// CALENDAR
export * from "./Calendar";
export * from "./MisionsGeneral";
export * from "./profile";
export * from "./header";
export * from "./ourSolutions";
// index
const financial =
  "https://storage.googleapis.com/rokinapp-images/Bussiness/financial%20(1).png";
const impact4 =
  "https://storage.googleapis.com/rokinapp-images/Bussiness/Impac4%20(1).png";
const impact3 =
  "https://storage.googleapis.com/rokinapp-images/Bussiness/impacs3_compressed%20(1).jpeg";
const impact2 =
  "https://storage.googleapis.com/rokinapp-images/Bussiness/impacs2_compressed%20(1).jpeg";
const impact1 =
  "https://storage.googleapis.com/rokinapp-images/Bussiness/impacts1_compressed.jpeg";
const porcentage =
  "https://storage.googleapis.com/rokinapp-images/Bussiness/porcentage%20(1).svg";
// mission 3
const feeding =
  "https://rokinapp-images.s3.amazonaws.com/mission3/alimentacion.png";
const transportation =
  "https://rokinapp-images.s3.amazonaws.com/mission3/transporte.png";
const health = "https://rokinapp-images.s3.amazonaws.com/mission3/salud.png";
const education =
  "https://rokinapp-images.s3.amazonaws.com/mission3/educacion.png";
const credit =
  "https://rokinapp-images.s3.amazonaws.com/mission3/creditosYCuotas.png";
const others = "https://rokinapp-images.s3.amazonaws.com/mission3/otros.png";
const newCategory =
  "https://rokinapp-images.s3.amazonaws.com/mission3/nuevaCategoria.png";
const dwelling =
  "https://rokinapp-images.s3.amazonaws.com/mission3/vivienda.png";
const accounts =
  "https://rokinapp-images.s3.amazonaws.com/mission3/cuentas.png";
const insurance =
  "https://rokinapp-images.s3.amazonaws.com/mission3/seguros.png";
const bought =
  "https://rokinapp-images.s3.amazonaws.com/mission3/peque%C3%B1asCompras.png";
const averageSpending =
  "https://rokinapp-images.s3.amazonaws.com/mission3/gastoPromedio.png";
const averageVariable =
  "https://rokinapp-images.s3.amazonaws.com/mission3/gastoVariable.png";
const fixedCost =
  "https://rokinapp-images.s3.amazonaws.com/mission3/GastoFijo.png";
const entertainment =
  "https://rokinapp-images.s3.amazonaws.com/mission3/entretencion.png";
// tools
const budgetIlustration =
  "https://rokinapp-images.s3.amazonaws.com/mission3/presupuesto_ilustracion.png";
const recomendationIcon =
  "https://rokinapp-images.s3.amazonaws.com/mission3/recomendaciones_icon.svg";
const saving =
  "https://rokinapp-images.s3.amazonaws.com/tools/ahorroEInversion_ilustracion.png";
const profileIlustration =
  "https://rokinapp-images.s3.amazonaws.com/tools/perfil_ilustracion.png";
const insuranceIlustration =
  "https://rokinapp-images.s3.amazonaws.com/tools/jubilacionSeguros_ilustracion.png";
const creditAndDebt =
  "https://rokinapp-images.s3.amazonaws.com/tools/creditoYDeudas_ilustracion.png";

// MISION 4A, Video
const videoM4ALoansAndDebts =
  "https://rokinapp-images.s3.amazonaws.com/mission4/Video+explainer+M.A.4.mp4";
const einsteinImage =
  "https://rokinapp-images.s3.amazonaws.com/einstein_img.png";
const BILLED_AMOUNT =
  "https://rokinapp-images.s3.amazonaws.com/creditCard/montoFacturado.svg";
const BILLING_DATE =
  "https://rokinapp-images.s3.amazonaws.com/creditCard/fechaDeFacturacion.svg";
const PAYMENT_DATE =
  "https://rokinapp-images.s3.amazonaws.com/creditCard/fechaDePago.svg";
const TIME_LINE_1 =
  "https://rokinapp-images.s3.amazonaws.com/creditCard/lineaDeTiempo_1.svg";
const TIME_LINE_2 =
  "https://rokinapp-images.s3.amazonaws.com/creditCard/lineaDeTiempo_2.svg";
const TIME_LINE_3 =
  "https://rokinapp-images.s3.amazonaws.com/creditCard/lineaDeTiempo_3.svg";
const TIME_LINE_4 =
  "https://rokinapp-images.s3.amazonaws.com/creditCard/lineaDeTiempo_4.svg";

// MISSION MB1

const DAP_ICON = "https://rokinapp-images.s3.amazonaws.com/M.B.1/DAP_icon.svg";
const ACTIONS_ICON =
  "https://rokinapp-images.s3.amazonaws.com/M.B.1/acciones_icon.svg";
const FOUND_MUTUAL =
  "https://rokinapp-images.s3.amazonaws.com/M.B.1/fondosMutuos_icon.svg";
const FOUND_INVESTMENT_ICON =
  "https://rokinapp-images.s3.amazonaws.com/M.B.1/fondoInversion_icon.svg";
const SURE_ICON =
  "https://rokinapp-images.s3.amazonaws.com/M.B.1/seguros_icon.svg";
const ETF_ICON = "https://rokinapp-images.s3.amazonaws.com/M.B.1/ETF_icon.svg";
const APV_ICON = "https://rokinapp-images.s3.amazonaws.com/M.B.1/APV_icon.svg";
const AFP_ICON = "https://rokinapp-images.s3.amazonaws.com/M.B.1/AFP_icon.svg";
const BILL_ICON =
  "https://rokinapp-images.s3.amazonaws.com/M.B.1/cuenta2_icon.svg";

// MISSION MB3
const SIMULATOR_DAP_ILUSTRATION =
  "https://rokinapp-images.s3.amazonaws.com/termDeposit/SimuladorDAP_ilustracion.svg";
const EYE_ICON =
  "https://rokinapp-images.s3.amazonaws.com/termDeposit/ojo_icon.svg";

const ADD_ICON = "https://rokinapp-images.s3.amazonaws.com/icons/add_icon.svg";
const CREDIT_ICON =
  "https://rokinapp-images.s3.amazonaws.com/icons/credit_icon.svg";
const CURRENT_BALANCE_ICON =
  "https://rokinapp-images.s3.amazonaws.com/icons/saldoActual_icon.svg";
const SECURITY_ICON =
  "https://rokinapp-images.s3.amazonaws.com/icons/security_icon.svg";
const DUAL_BALL =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/portal/Dual+Ball-1s-200px+(2).svg";
//LANDING COMPANY
const SOLUTION_ICON_1 =
  "https://storage.googleapis.com/rokinapp-images/Landing/SolutionsItems/ahorro.svg";
const SOLUTION_ICON_2 =
  "https://storage.googleapis.com/rokinapp-images/Landing/SolutionsItems/administracion.svg";
const SOLUTION_ICON_3 =
  "https://storage.googleapis.com/rokinapp-images/Landing/SolutionsItems/aprendizaje.svg";
const SOLUTION_ICON_4 =
  "https://storage.googleapis.com/rokinapp-images/Landing/SolutionsItems/acompaniamiento.svg";
const HELP_ICON =
  "https://rokinapp-images.s3.amazonaws.com/icons/help_icon.svg";
const TARGET_ICON =
  "https://storage.googleapis.com/rokinapp-images/icons/target_icon.svg";
const EYE_ICON_SECUNDARY =
  "https://storage.googleapis.com/rokinapp-images/icons/eye_icon.svg";

export {
  HELP_ICON,
  DUAL_BALL,
  CURRENT_BALANCE_ICON,
  CREDIT_ICON,
  SIMULATOR_DAP_ILUSTRATION,
  EYE_ICON,
  BILL_ICON,
  ETF_ICON,
  AFP_ICON,
  APV_ICON,
  SURE_ICON,
  DAP_ICON,
  ACTIONS_ICON,
  FOUND_INVESTMENT_ICON,
  FOUND_MUTUAL,
  TIME_LINE_1,
  TIME_LINE_2,
  TIME_LINE_3,
  TIME_LINE_4,
  BILLED_AMOUNT,
  BILLING_DATE,
  PAYMENT_DATE,
  insuranceIlustration,
  creditAndDebt,
  profileIlustration,
  financial,
  saving,
  impact1,
  impact2,
  impact3,
  impact4,
  porcentage,
  feeding,
  transportation,
  health,
  education,
  credit,
  others,
  newCategory,
  dwelling,
  accounts,
  insurance,
  bought,
  averageSpending,
  averageVariable,
  fixedCost,
  entertainment,
  budgetIlustration,
  recomendationIcon,
  videoM4ALoansAndDebts, // video
  einsteinImage,
  ADD_ICON,
  SECURITY_ICON,
  TARGET_ICON,
  EYE_ICON_SECUNDARY,
  SOLUTION_ICON_1,
  SOLUTION_ICON_2,
  SOLUTION_ICON_3,
  SOLUTION_ICON_4,
};
