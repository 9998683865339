import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  Footer,
  GoalSelector,
  InvestPeriodRangeSelector,
  NumericInput,
  TextInput,
  FoundSelector,
} from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { arrowGoal, createMobile } from "images/Goal";

interface LayoutProps {
  className?: string;
  styleFooter?: string;
  icon?: string;
  buttonContinue: string;
  title: string;
  secondTitle?: string;
  iconFooter: string;
  children: React.ReactNode;
  pageName: string;
}
export const LayoutMobile: React.FC<LayoutProps> = ({
  className,
  children,
  iconFooter,
  setStepNumber,
  stepNumber,
  pageName,
}) => {
  const classes = useStyles();
  document.body.style.background = "#F4F3F3";
  const handleClick = () => {
    if (stepNumber === 0) {
      navigate(Route.investments + "/?#objectives");
    }
    if (stepNumber === 1) {
      setStepNumber(0);
    }
    if (stepNumber === 2) {
      navigate(Route.investments);
    }
  };
  return (
    <div className={`${className || ""} ${classes.container}`}>
      <section className={classes.header}>
        <img onClick={handleClick} src={arrowGoal} />
        <div className={classes.titleHeader}>{pageName}</div>
      </section>
      {children}
      <footer className={classes.footer} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    fontFamily: "Montserrat",
    background: "#F4F3F3",
  },
  header: {
    background:
      "linear-gradient(161deg, rgb(0, 170, 188) 30%, rgb(5, 130, 149) 63%, rgb(10, 86, 105) 88%)",
    paddingTop: 30,
    borderRadius: "0 0 30px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingBottom: 15,
    marginBottom: 0,
    "& img": {
      width: 30,
      marginLeft: "1rem",
    },
    "& >div": {
      paddingLeft: "3rem",
    },
  },
  titleHeader: {
    color: "white",
    fontSize: 20,
  },
  txt: {
    display: "block",
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.text.primary,
    paddingBottom: 15,
  },
  containerGoal: {
    padding: "0 20px",
    margin: "0 auto",
    width: 375,
  },
  input: {
    width: 335,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: "bold",
    paddingTop: 30,
    display: "block",
  },
  buttons: {
    marginTop: 50,
    display: "grid",
    "& button": {
      marginBottom: 22,
    },
  },
  footer: {
    width: "auto",
    height: 55,
    position: "relative",
  },
}));
