import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconBox: {
    width: "2.938rem",
    height: "2.938rem",
    borderRadius: 8,
    boxShadow: "2.8px 2.8px 8.5px -0.1px rgb(0 0 0 / 20%)",
  },
  titlePercentage: {
    fontSize: "0.875rem",
    fontWeight: 300,
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
  },
  progress: {
    marginLeft: 18,
    display: "flex",
    flexDirection: "column",
    "& svg": {
      borderRadius: "15px",
    },
  },
  goals: {
    display: "flex",
    justifyContent: "space-between",
  },
  goal: {
    fontSize: "0.6rem",
    fontWeight: 200,
  },
  left: {
    fontSize: "0.6rem",
    fontWeight: 200,
  },
}));
