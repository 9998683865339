export * from "./nationality";
export * from "./maritalStates";
export * from "./maritalContract";
export * from "./fundsSource";
export * from "./bank";
export * from "./bankAccountType";
export * from "./investFrequency";
export * from "./numberOptions";
export * from "./jobType";
export * from "./reasonsForContact";
export * from "./timeGaps";
export * from "./afpTypes";
export * from "./categoryOptions";
export * from "./bankFinerio";
export * from "./suscriptions";
export * from "./financialProducts";


export interface Option {
  name: string;
  value: string | number;
}

export const cropOptions = (options: any[]): Option[] =>
  options.map(({ name, value }) => ({ name, value }));

export const GENDER_OPTIONS = ["Masculino", "Femenino", "Otro"].map(g => ({
  name: g,
  value: g,
}));

export const INCOMES_OPTIONS = [
  "Menos de $700.000",
  "Entre $700.001 y $1.500.000",
  "Entre $1.500.001 y $2.500.000",
  "Entre $2.500.001 y $3.500.000",
  "Entre $3.500.001 y $5.000.000",
  "Más de $5.000.000",
].map(i => ({
  name: i,
  value: i,
}));

export const MONTHLY_SAVING_OPTIONS = Array(11)
  .fill(0)
  .map((_, i) => 10 * i)
  .map(g => ({
    name: String(g) + " %",
    value: String(g),
  }));

interface NumericRangeOption {
  name: string;
  min?: number;
  max?: number;
}
export const SAVED_CAPITAL_OPTIONS: NumericRangeOption[] = [
  { name: "Menos de $1.000.000", max: 1000000 },
  { name: "Entre $1.000.000 y $5.000.000", min: 1000001, max: 5000000 },
  { name: "Entre $5.000.001 y $20.000.000", min: 5000001, max: 20000000 },
  { name: "Entre $20.000.001 y $50.000.000", min: 20000001, max: 50000000 },
  { name: "Entre $50.000.001 y $100.000.000", min: 5000001, max: 100000000 },
  { name: "Más de $100.000.000", min: 100000001 },
].map(g => ({
  ...g,
  value: g.name,
}));

export const MAIN_GOAL_OPTIONS = [
  "Corto plazo",
  "Gusto personal (auto, viaje, compras)",
  "Estudios",
  "Invertir mi plata",
  "Comprar una casa",
  "Jubilación",
].map(g => ({
  name: g,
  value: g,
}));

export const TERM_OPTIONS = [
  "Menos de 1 año",
  "Entre 1 y 3 años",
  "Entre 3 y 5 años",
  "Más de 5 años",
].map(g => ({
  name: g,
  value: g,
}));

export const SMART_RISK_OPTIONS = [
  "ganar lo mínimo con tal de no perder nada",
  "ganar $30 mil, pudiendo perder $10 mil",
  "ganar $100 mil, pudiendo perder $40 mil",
  "ganar $150 mil, pudiendo perder $80 mil",
  "ganar $300 mil, pudiendo perder $150 mil",
  "ganar $500 mil, pudiendo perder $400 mil",
].map(i => ({
  name: i,
  value: i,
}));

export const RISK_LEVELS = [
  "Muy conservador",
  "Conservador",
  "Moderado",
  "Arriesgado",
  "Muy arriesgado",
  "Agresivo",
];

export const EXPERIENCE_LEVELS = [
  "Nada",
  "Poco",
  "Bastante",
  "Soy experto",
].map(i => ({
  name: i,
  value: i,
}));
export const CONCERNS_OPTIONS = [
  "Me preocupa mucho perder",
  "Me preocupa lo mismo ganar que perder",
  "Me preocupa mucho ganar",
].map(i => ({
  name: i,
  value: i,
}));
export const CURRENT_INVESTMENTS_OPTIONS = [
  "Nada",
  "Bonos y/o depósitos",
  "Acciones y/o mutuos",
  "Acciones y/o derivados extranjeros",
].map(i => ({
  name: i,
  value: i,
}));
export const DROP_RESPONSE_OPTIONS = [
  "Vendo todo",
  "Vendo una parte",
  "Me quedo igual",
  "Invierto más",
].map(i => ({
  name: i,
  value: i,
}));

export const INVESTOR_PROFILE_OPTIONS = [
  "Conservador",
  "Conservador Moderado",
  "Moderado",
  "Moderado Agresivo",
  "Agresivo",
].map(i => ({
  name: i,
  value: i,
}));

export const FIRST_FUND_OPTIONS = [
  "Conservador",
  "Moderado",
  "Agresivo",
  "S&P500"
].map(i => ({
  name: i,
  value: i,
}));
