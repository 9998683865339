import React, { useContext } from "react";
import { Dialog } from "@material-ui/core";
import { AppContext } from "@context";
import {
  ButtonOutlined,
  ButtonPrimary,
  NumericInput,
} from "@components/Reusables";
import { makeStyles } from "@material-ui/core";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { moneyModal } from "images/Goal";

interface MissionsModalProps {
  open: boolean;
}

export const GoalModal: React.FC<MissionsModalProps> = ({ open }) => {
  const classes = useStyles();
  const { closeGoalModal } = useContext(AppContext);

  return (
    <>
      <Dialog open={open} onClose={closeGoalModal} className={classes.Dialog}>
        <div className={classes.Modal}>
          <div className={classes.info}>
            <div className={classes.titulo}>
              <img src={moneyModal} />
              Agregar Meta
            </div>
            <span className={classes.description}>
              Ingresa el monto que quieres alcanzar para este objetivo
            </span>
            <div className={classes.line}></div>
            <NumericInput className={classes.numericInput} name={""} />
            <div className={classes.buttons}>
              <ButtonOutlined
                className={classes.cancel}
                onClick={() => closeGoalModal}
                text="Cancelar"
              />{" "}
              <ButtonPrimary
                className={classes.create}
                onClick={() => {
                  navigate(Route.createGoal);
                  closeGoalModal();
                }}
                text="Crear Meta"
                secondaryVersion
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    Dialog: {
      color: theme.palette.text.primary,
      opacity: 0.9,
      maxWidth: 900,
      "& >div >div": {
        borderRadius: "35px",
      },
    },
    line: {
      width: "85%",
      position: "absolute",
      top: 110,
      display: "flex",
      justifyContent: "center",
      borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
    },
    Modal: {
      width: 800,
      height: 380,
      flexGrow: 0,
      padding: "22px 44px 22px 44px",
      borderRadius: 35,
      [theme.breakpoints.down(700)]: {
        width: 500,
        minHeight: 315,
        height: 425,
        padding: "15px 10px",
      },
      [theme.breakpoints.down(600)]: {
        width: 300,
      },
    },
    info: {
      display: "grid",
    },
    titulo: {
      display: "flex",
      width: "92%",
      alignItems: "center",
      fontSize: 24,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      [theme.breakpoints.down(800)]: {
        fontSize: 20,
      },
    },
    description: {
      fontSize: 16,
      display: "block",
      paddingTop: 10,
      paddingBottom: 30,
      [theme.breakpoints.down(800)]: {
        fontSize: 14,
      },
    },
    create: {
      width: 140,
      height: 50,
      minWidth: 140,
      [theme.breakpoints.down(600)]: {
        width: 200,
      },
    },
    cancel: {
      width: 100,
      height: 50,
      marginRight: "1rem",
      [theme.breakpoints.down(600)]: {
        width: 200,
        margin: 0,
      },
    },
    numericInput: {
      width: 160,
      height: 50,
      margin: "0 auto",
      "& >div": { height: 102 },
    },
    buttons: {
      marginTop: "2rem",
      width: 261,
      margin: "0 auto",
      [theme.breakpoints.down(600)]: {
        display: "grid",
        justifyContent: "center",
        gap: "1rem",
      },
    },
  }),
  { index: 1 }
);
