import {
  FIXED_EXPENSES_3D,
  HOLIDAY_BONUS_3D,
  NUTRITION_3D,
  BONUS_3D,
  COMISSIONS_3D,
  CREDITS_AND_QUOTAS_3D,
  ACCOUNTS_3D,
  EDUCATION_3D,
  ENTERTAINMENT_3D,
  AVERAGE_EXPENSES_3D,
  VARIABLE_EXPENSES_3D,
  HONORARIES_3D,
  FIXED_INCOME_3D,
  AVERAGE_INCOME_3D,
  VARIABLE_INCOME_3D,
  NEW_CATEGORY_3D,
  NEW_EXPENSE_3D,
  OTHERS_3D,
  SMALL_PURCHASES_3D,
  BUDGET_ILUSTRATION_3D,
  RECOMENDATIONS_ILUSTRATION_3D,
  HEALTH_3D,
  INSURANCE_3D,
  SALARY_3D,
  TRANSPORTATION_3D,
  LIVING_PLACE_3D,
} from "images/GCP/habits/addMovement";

export const categorysList3D = [
  {
    id: 0,
    name: "sueldo",
    image: FIXED_INCOME_3D,
  },
  {
    id: 1,
    name: "honorarios",
    image: HONORARIES_3D,
    backgroundColor: "#084455",
  },
  {
    id: 2,
    name: "Alimentación",
    image: NUTRITION_3D,
    backgroundColor: "#084455",
  },
  {
    id: 3,
    name: "Transporte",
    image: TRANSPORTATION_3D,
    backgroundColor: "#084455",
  },
  {
    id: 4,
    name: "Salud",
    image: HEALTH_3D,
    backgroundColor: "#084455",
  },
  {
    id: 5,
    name: "EDUCACIÓN",
    image: EDUCATION_3D,
    backgroundColor: "#084455",
  },
  {
    id: 6,
    name: "crédito y cuotas",
    image: CREDITS_AND_QUOTAS_3D,
    backgroundColor: "#084455",
  },
  {
    id: 7,
    name: "vivienda",
    image: LIVING_PLACE_3D,
    backgroundColor: "#084455",
  },
  {
    id: 8,
    name: "cuentas",
    image: ACCOUNTS_3D,
    backgroundColor: "#084455",
  },
  {
    id: 9,
    name: "seguros",
    image: INSURANCE_3D,
    backgroundColor: "#084455",
  },
  {
    id: 10,
    name: "entretenimiento",
    image: ENTERTAINMENT_3D,
    backgroundColor: "#084455",
  },
  {
    id: 11,
    name: "Pequeñas compras",
    image: SMALL_PURCHASES_3D,
    backgroundColor: "#084455",
  },
  {
    id: 12,
    name: "otros",
    image: OTHERS_3D,
    backgroundColor: "#084455",
  },
];
