import { User } from "@interfaces";
import { InvestmentTermType, InvestorProfileType } from "./investor_profile";
import { RegisterForm } from "./register";
import { AltercapUser } from "./user";
import { Company } from "./company";

export const FORM_FIELDS = [
  "name",
  "email",
  "company",
  "password",
  "birthdate",
  "gender",
  "profession",
  "incomes",
  "monthlySaving",
  "savedCapital",
  "mainGoal",
  "investmentTerm",
  "risk",
  "investmentExperience",
  "investmentConcern",
  "currentInvesting",
  "investmentDropResponse",
  "preferences",
];

export interface ProfileForm {
  /* PERFIL BÁSICO > Datos básicos */
  uuid: string;
  name: string;
  email: string;
  company: Company | null;
  password: string;
  birthdate: Date | null;
  gender: string;
  profession: string;
  /* PERFIL BÁSICO > Perfil de inversionista */
  savedCapital: string;
  mainGoal: string;
  investmentTerm: InvestmentTermType | null | string;
  risk: string;
  investmentExperience: string;
  investmentConcern: string;
  currentInvesting: string;
  investmentDropResponse: string;
  investorProfile: InvestorProfileType | null;
  manuallySelectedInvestorProfile: boolean;

  /* PARA INVERTIR > Datos básicos */
  paternalSurname: string;
  maternalSurname: string;
  rut: string;
  nationality: string;
  jobType: string;
  workPosition: string;
  employerName: string;
  maritalState: string;
  maritalPropertyRegime: string;
  spouseName: string;
  spousePaternalSurname: string;
  spouseMaternalSurname: string;
  spouseRut: string;
  spouseBirthdate: Date | null;
  spouseNationality: string;
  spouseAddress: string;
  spouseGender: string;
  address: string;
  apartment: string;
  phone: string;
  fundsSource: string;
  isUSperson: boolean | null;
  isPEP: boolean | null;
  isQualifiedInvestor: boolean | null;
  autoInvestCashSurplus: boolean | null;
  agreement: boolean;
  hasAGFrelation: boolean | null;
  hasEnterpriseRelation: boolean | null;

  /* PARA INVERTIR > Datos bancarios */
  bank: string;
  accountType: string;
  accountNumber: string;
  /* PARA INVERTIR > Archivos */

  /* VALIDACIONES */
  isPersonalDataCompleted: boolean;
  isSpouseDataCompleted: boolean;
  isSpecialDataCompleted: boolean;
  isBankDataCompleted: boolean;
  isIdentityValidated: boolean;
  areDocumentsSigned: boolean;
  isOnboardingCompleted: boolean;

  /* OTROS */
  incomes: string;
  monthlySaving: string;
  wantInfo: boolean;
  preferences: { onBoarding: number[]; budgetGoals: JSON[] };

  /* REFINANCE */
  dataRefinanceCredit?: any;
  liquidationAmount?: string;
  liquidationFile?: string;
  debtReport?: number | any;
  contactEmail?: string;

  //Budget mission2
  fixedIncome: {
    name: string;
    amount: number;
  }[];
  variableIncome: {
    name: string;
    months: number;
    amounts: number[];
  }[];
  //budgetMission3
  billsFixed: { family: any; house: any };
  billsVariable: { all: number };

  family: any;
  house: any;
  //budgetMission4
  entry: any;
  spent: any;

  totalVariableIncome: number;
  totalFixedIncome: number;

  /* SERVICES */
  servicesForm: any;
  newMortgageCredit: any;
  /*  {
    tipoDeVivienda: string;
    estado: string;
    columna: string;
    valor: string;
    pie: number;
    plazo?: string;
    cotizacion: {
      tipo: string;
      detallada: {
        carnetFront?: string;
        carnetBack?: string;
        liquidaciones: {
          liquidacion1?: string;
          liquidacion2?: string;
          liquidacion3?: string;
        };
      };
    };
  } */
  newConsumptionCredit: any;
  newAutomotiveCredit: {
    estado: string;
    marca: string;
    modelo: string;
    año: number;
    precio: number;
    pie: number;
    motivo: any;
    pasajeros: any;
    seguridad: any;
    diseno: any;
    tecnologia: any;
    rendimiento: any;
  };
  solicitudeConsolidateCredit: boolean;
  liquidationFile1: string | undefined;
  liquidationFile2: string | undefined;
  liquidationFile3: string | undefined;

  // SERVICES INVESTMENT
  planAGoal: any;
  instrumentsToInvest: string;
  investmentProfile: any;

  // SERVICES RECURRING PAYMENTS
  recurringPayments: any;

  //SURE
  sure: any;
}

export type ProfileFormErrors = Partial<{
  [P in keyof ProfileForm]: boolean;
}>;

export const DEFAULT_FORM: ProfileForm = {
  /* PERFIL BÁSICO > Datos básicos */
  uuid: "",
  name: "",
  email: "",
  company: null,
  password: "",
  birthdate: null,
  gender: "",
  profession: "",
  /* PERFIL BÁSICO > Perfil de inversionista */
  savedCapital: "",
  mainGoal: "",
  investmentTerm: null,
  risk: "",
  investmentExperience: "",
  investmentConcern: "",
  currentInvesting: "",
  investmentDropResponse: "",
  investorProfile: null,
  manuallySelectedInvestorProfile: false,

  /* PARA INVERTIR > Datos básicos */
  paternalSurname: "",
  maternalSurname: "",
  rut: "",
  nationality: "Chile",
  jobType: "",
  workPosition: "",
  employerName: "",
  maritalState: "Soltero/a",
  maritalPropertyRegime: "",
  spouseName: "",
  spousePaternalSurname: "",
  spouseMaternalSurname: "",
  spouseRut: "",
  spouseBirthdate: null,
  spouseNationality: "Chile",
  spouseAddress: "",
  spouseGender: "",
  address: "",
  apartment: "",
  phone: "",
  fundsSource: "",
  isUSperson: null,
  isPEP: null,
  isQualifiedInvestor: null,
  autoInvestCashSurplus: null,
  agreement: false,
  hasAGFrelation: null,
  hasEnterpriseRelation: null,
  /* PARA INVERTIR > Datos bancarios */
  bank: "",
  accountType: "",
  accountNumber: "",
  /* PARA INVERTIR > Archivos */
  /* VALIDACIONES */
  isPersonalDataCompleted: false,
  isSpouseDataCompleted: false,
  isSpecialDataCompleted: false,
  isBankDataCompleted: false,
  isIdentityValidated: false,
  areDocumentsSigned: false,
  isOnboardingCompleted: false,

  /* OTROS */
  incomes: "",
  monthlySaving: "",
  wantInfo: false,
  preferences: { onBoarding: [], budgetGoals: [] },
  fixedIncome: [],
  variableIncome: [],
  billsFixed: {
    family: undefined,
    house: undefined,
  },
  billsVariable: {
    all: 0,
  },
  family: undefined,
  house: undefined,
  entry: undefined,
  spent: undefined,
  totalVariableIncome: 0,
  totalFixedIncome: 0,

  newMortgageCredit: "" /*  {
    tipoDeVivienda: "",
    estado: "",
    columna: "",
    valor: "",
    pie: -1,
    plazo: "",
    cotizacion: {
      tipo: "",
      detallada: {
        carnetFront: "",
        carnetBack: "",
        liquidaciones: {
          liquidacion1: "",
          liquidacion2: "",
          liquidacion3: "",
        },
      },
    },
  } */,
  newConsumptionCredit: "",
  newAutomotiveCredit: {
    estado: "",
    marca: "",
    modelo: "",
    año: 0,
    precio: 0,
    pie: 0,
    motivo: "",
    pasajeros: "",
    seguridad: "",
    diseno: "",
    tecnologia: "",
    rendimiento: "",
  },
  solicitudeConsolidateCredit: false,
  liquidationFile1: "",
  liquidationFile2: "",
  liquidationFile3: "",

  planAGoal: "",
  instrumentsToInvest: "",
  investmentProfile: "",
  recurringPayments: "",
  sure: "",
};

export const DEFAULT_FORM_ERRORS: ProfileFormErrors = Object.assign(
  {},
  ...Object.keys(DEFAULT_FORM).map((key) => ({
    [key]: false,
  }))
);

export interface ProfileDialogProps {
  className?: string;
  open: boolean;
  onClose: () => void;
}

export const userToProfileForm = (user: AltercapUser | User): ProfileForm => ({
  uuid: user.uuid ?? DEFAULT_FORM.uuid,
  name: user.name ?? DEFAULT_FORM.name,
  email: user.email ?? DEFAULT_FORM.email,
  company: user.company ?? DEFAULT_FORM.company,
  password: user.password ?? DEFAULT_FORM.password,
  birthdate: user.personalData?.birthdate ?? DEFAULT_FORM.birthdate,
  gender: user.personalData?.gender ?? DEFAULT_FORM.gender,
  profession: user.personalData?.profession ?? DEFAULT_FORM.profession,
  /* PERFIL BÁSICO > Perfil de inversionista */
  savedCapital: user.investorProfile?.savedCapital ?? DEFAULT_FORM.savedCapital,
  mainGoal: user.investorProfile?.mainGoal ?? DEFAULT_FORM.mainGoal,
  investmentTerm:
    user.investorProfile?.investmentTerm ?? DEFAULT_FORM.investmentTerm,
  risk: user.investorProfile?.risk ?? DEFAULT_FORM.risk,
  investmentExperience:
    user.investorProfile?.investmentExperience ??
    DEFAULT_FORM.investmentExperience,
  investmentConcern:
    user.investorProfile?.investmentConcern ?? DEFAULT_FORM.investmentConcern,
  currentInvesting:
    user.investorProfile?.currentInvesting ?? DEFAULT_FORM.currentInvesting,
  investmentDropResponse:
    user.investorProfile?.investmentDropResponse ??
    DEFAULT_FORM.investmentDropResponse,
  investorProfile:
    user.investorProfile?.investorProfile ?? DEFAULT_FORM.investorProfile,
  manuallySelectedInvestorProfile:
    user.investorProfile?.manuallySelectedInvestorProfile ??
    DEFAULT_FORM.manuallySelectedInvestorProfile,

  /* PARA INVERTIR > Datos básicos */
  paternalSurname:
    user.personalData?.paternalSurname ?? DEFAULT_FORM.paternalSurname,
  maternalSurname:
    user.personalData?.maternalSurname ?? DEFAULT_FORM.maternalSurname,
  rut: user.personalData?.rut ?? DEFAULT_FORM.rut,
  nationality: user.personalData?.nationality ?? DEFAULT_FORM.nationality,
  jobType: user.personalData?.jobType ?? DEFAULT_FORM.jobType,
  workPosition: user.personalData?.workPosition ?? DEFAULT_FORM.workPosition,
  employerName: user.personalData?.employerName ?? DEFAULT_FORM.employerName,
  maritalState: user.personalData?.maritalState ?? DEFAULT_FORM.maritalState,
  maritalPropertyRegime:
    user.personalData?.maritalPropertyRegime ??
    DEFAULT_FORM.maritalPropertyRegime,
  spouseName: user.personalData?.spouseName ?? DEFAULT_FORM.spouseName,
  spousePaternalSurname:
    user.personalData?.spousePaternalSurname ??
    DEFAULT_FORM.spousePaternalSurname,
  spouseMaternalSurname:
    user.personalData?.spouseMaternalSurname ??
    DEFAULT_FORM.spouseMaternalSurname,
  spouseRut: user.personalData?.spouseRut ?? DEFAULT_FORM.spouseRut,
  spouseBirthdate:
    user.personalData?.spouseBirthdate ?? DEFAULT_FORM.spouseBirthdate,
  spouseNationality:
    user.personalData?.spouseNationality ?? DEFAULT_FORM.spouseNationality,
  spouseAddress: user.personalData?.spouseAddress ?? DEFAULT_FORM.spouseAddress,
  spouseGender: user.personalData?.spouseGender ?? DEFAULT_FORM.spouseGender,
  address: user.personalData?.address ?? DEFAULT_FORM.address,
  apartment: user.personalData?.apartment ?? DEFAULT_FORM.apartment,
  phone: user.personalData?.phone ?? DEFAULT_FORM.phone,
  fundsSource: user.personalData?.fundsSource ?? DEFAULT_FORM.fundsSource,
  /* PARA INVERTIR > Datos bancarios */
  bank: user.bankData?.bank ?? DEFAULT_FORM.bank,
  accountType: user.bankData?.accountType ?? DEFAULT_FORM.accountType,
  accountNumber: user.bankData?.accountNumber ?? DEFAULT_FORM.accountNumber,
  isUSperson: user.personalData?.isUSperson ?? DEFAULT_FORM.isUSperson,
  isPEP: user.personalData?.isPEP ?? DEFAULT_FORM.isPEP,
  isQualifiedInvestor:
    user.personalData?.isQualifiedInvestor ?? DEFAULT_FORM.isQualifiedInvestor,
  autoInvestCashSurplus:
    user.personalData?.autoInvestCashSurplus ??
    DEFAULT_FORM.autoInvestCashSurplus,
  agreement: user.personalData?.agreement ?? DEFAULT_FORM.agreement,
  hasAGFrelation:
    user.personalData?.hasAGFrelation ?? DEFAULT_FORM.hasAGFrelation,
  hasEnterpriseRelation:
    user.personalData?.hasEnterpriseRelation ??
    DEFAULT_FORM.hasEnterpriseRelation,

  /* PARA INVERTIR > Archivos */

  /* VALIDACIONES */
  isPersonalDataCompleted: user.isPersonalDataCompleted,
  isSpouseDataCompleted: user.isSpouseDataCompleted,
  isSpecialDataCompleted: user.isSpecialDataCompleted,
  isBankDataCompleted: user.isBankDataCompleted,
  isIdentityValidated: user.isIdentityValidated,
  areDocumentsSigned: user.areDocumentsSigned,
  isOnboardingCompleted: user.isOnboardingCompleted,

  /* OTROS */
  incomes: user.personalData?.incomes ?? DEFAULT_FORM.incomes,
  monthlySaving: user.personalData?.monthlySaving ?? DEFAULT_FORM.monthlySaving,
  wantInfo: user.wantInfo ?? DEFAULT_FORM.wantInfo,
  preferences: user.preferences ?? DEFAULT_FORM.preferences,

  /*SERVICES */
});

export const DEFAULT_REGISTER_FORM: RegisterForm = {
  useType: null,
  investAmount: 2000000,
  dontKnowInvestAmount: false,
  investorProfile: null,
  goalName: "",
  goalAmount: 5000000,
  goalTerm: 2,
  name: "",
  email: "",
  birthdate: null,
  password: "",
  passwordCheck: "",
  investmentTerm: null,
  investmentRisk: "",
  investmentExperience: "",
  investmentConcern: "",
  currentInvesting: "",
  investmentDropResponse: "",
  dataRefinanceCredit: undefined,
  newMortgageCredit: "" /* {
    tipoDeVivienda: "",
    estado: "",
    columna: "",
    valor: "",
    pie: 0,
    plazo: "",
    cotizacion: {
      tipo: "",
      detallada: {
        carnetFront: "",
        carnetBack: "",
        liquidaciones: {
          liquidacion1: "",
          liquidacion2: "",
          liquidacion3: "",
        },
      },
    },
  } */,
  servicesForm: "",
  newConsumptionCredit: "",
  newAutomotiveCredit: {
    estado: "",
    marca: "",
    modelo: "",
    año: 0,
    precio: 0,
    pie: 0,

    motivo: "",
    pasajeros: "",
    seguridad: "",
    diseno: "",
    tecnologia: "",
    rendimiento: "",
  },
  solicitudeConsolidateCredit: false,
  liquidationFile1: "",
  liquidationFile2: "",
  liquidationFile3: "",

  planAGoal: "",
  instrumentsToInvest: "",
  investmentProfile: "",
  recurringPayments: "",
  sure: "",
};
