import React, { useCallback, useContext, useState, useEffect } from "react";
import { navigate, PageProps } from "gatsby";
import { IconButton, makeStyles } from "@material-ui/core";
import { SEO, ButtonPrimary, CheckboxInput } from "@components";
import { LayoutBildRegistration } from "@components/Structural/RegistrationStep1";
import { useApolloClient } from "@apollo/client";
import { LoadingContext, MissionsContext, ProfileContext } from "@context";
import { useForm } from "@hooks";
import { ContractFileType, FileSpecificType, Route } from "@interfaces";
import {
  generateContractPreview,
  createVectorAccount,
  getIdentityFileSignedUrl,
} from "@apollo";
import { useDropzone } from "react-dropzone";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { arrowTop, CHECK_ICON, IMAGE_ICON } from "@images";
import { AppContext } from "@context";

enum LinkType {
  PEP = "PEP",
  QUALIFIED_INVESTOR = "Inversionista Calificado",
}

interface ConfirmIdentityProps {
  form: any;
  buttonText: string;
  handleClick: () => void;
  isNotMission?: boolean;
  urls?: any;
  sendImagesFromProfile?: () => void;
}
// IMPORTANT TO-DO! (27-12-2022 / BY: AGUSTÍN ZALAQUETT) ( todo / TODO / to-do / TO-DO )
// IN CASE OF REACHING THIS COMPONENT BY THE "MI PERFIL" PATH: (isNotMission = true)
// THE COMPONENT IS NO CORRECTLY UPDATING THE DATA, IT IS ONLY SAVING THE DATA IN THE DATABASE,
// BUT IS NO SENDING IT TO VERTOR FOR THE VERIFICATION PROCESS.
// PARENTHESIS: THE DATA MUST BE SENT ONCE A YEAR TO VERTOR FOR THE VERIFICATION PROCESS, BY LAW.
export const ConfirmIdentity: React.FC<ConfirmIdentityProps> = ({
  form,
  buttonText,
  isNotMission,
  handleClick,
  sendImagesFromProfile,
  urls,
}) => {
  const classes = useStyles();
  const { openCongratsModal } = useContext(AppContext);
  const { setStepMission } = useContext(MissionsContext);
  const [frontIdentityChanged, setFrontIdentityChanged] =
    useState<boolean>(false);
  const [backIdentityChanged, setBackIdentityChanged] =
    useState<boolean>(false);

  const client = useApolloClient();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { identityFiles, saveIdentityFile } = useContext(ProfileContext);

  const [isUSPerson, setIsUSPerson] = useState<boolean>(
    form.isUSperson ? form.isUSperson : false
  );
  const [isPEP, setIsPEP] = useState<boolean>(form.isPEP ? form.isPEP : false);
  const [isQualifiedInvestor, setIsQualifiedInvestor] = useState<boolean>(
    form.isQualifiedInvestor ? form.isQualifiedInvestor : false
  );
  const [hasAGFrelation, setHasAGFrelation] = useState<boolean>(
    form.hasAGFrelation ? form.hasAGFrelation : false
  );
  const [hasEnterpriseRelation, setHasEnterpriseRelation] = useState<boolean>(
    form.hasEnterpriseRelation ? form.hasEnterpriseRelation : false
  );
  const [agreement, setAgreement] = useState<boolean>(
    form.agreement ? form.agreement : false
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [changingImage, setChangingImage] = useState<boolean>(false);

  const generateFile = useCallback(
    (fileType: ContractFileType) => {
      showLoader(`Generating file ${fileType}`);
      generateContractPreview(fileType, client).then((blob) => {
        hideLoader(`Generating file ${fileType}`);
        if (blob) window.open(blob, "_blank");
      });
    },
    [client, showLoader, hideLoader]
  );
  const generateContractFile = useCallback(
    () => generateFile(ContractFileType.CONTRACT),
    [generateFile]
  );
  const generateMandatoFile = useCallback(
    () => generateFile(ContractFileType.MANDATE),
    [generateFile]
  );

  const openWindowPEP = useCallback(
    () => window.open(LINKS[LinkType.PEP], "_blank"),
    []
  );
  const openWindowisQualifiedInvestor = useCallback(
    () => window.open(LINKS[LinkType.QUALIFIED_INVESTOR], "_blank"),
    []
  );
  const onDrop = useCallback(
    (type: "front" | "back") => (acceptedFiles: File[]) => {
      saveIdentityFile(acceptedFiles[0], type);
    },
    []
  );
  const frontDropzone = useDropzone({
    accept: "image/*",
    onDrop: onDrop("front"),
  });
  const backDropzone = useDropzone({
    accept: "image/*",
    onDrop: onDrop("back"),
  });

  useEffect(() => {
    form.isUSperson = isUSPerson;
    form.isPEP = isPEP;
    form.isQualifiedInvestor = isQualifiedInvestor;
    form.hasAGFrelation = hasAGFrelation;
    form.hasEnterpriseRelation = hasEnterpriseRelation;
    form.agreement = agreement;

    if (agreement && identityFiles.front && identityFiles.back) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    agreement,
    isUSPerson,
    isPEP,
    isQualifiedInvestor,
    hasAGFrelation,
    hasEnterpriseRelation,
    identityFiles,
  ]);

  return (
    <>
      <section
        className={`${classes.container} ${
          isNotMission && classes.notMissionContainer
        }`}
      >
        {isNotMission && (
          <>
            {console.log("urls", urls)}
            {urls && (
              <div>
                <div className={classes.urlsContainer}>
                  {urls.length > 0 && (
                    <>
                      <div className="section">
                        {/* <p>{urls[0].split("/")[urls[0].split("/").length - 1]}</p> */}
                        <img src={IMAGE_ICON} alt="" />
                        <p>Carnet frontal</p>

                        <img src={CHECK_ICON} alt="" />
                      </div>
                    </>
                  )}
                  {urls.length == 2 && (
                    <div className="section">
                      {/* <p>{urls[1].split("/")[urls[0].split("/").length - 1]}</p> */}
                      <img src={IMAGE_ICON} alt="" />

                      <p>Carnet trasero</p>
                      <img src={CHECK_ICON} alt="" />
                    </div>
                  )}
                  {urls.length == 0 && (
                    <div className="section">
                      <p>No hay carnet de identidad</p>
                    </div>
                  )}
                </div>
                <div
                  className={classes.updateButton}
                  onClick={() => setChangingImage(!changingImage)}
                >
                  Actualizar carnet de identidad
                  <div></div>
                </div>
              </div>
            )}
          </>
        )}
        {(!isNotMission || changingImage) && (
          <div
            className={`${classes.boxes} ${
              isNotMission && classes.notMissionBoxes
            } `}
          >
            <div className="dropzone" {...frontDropzone.getRootProps()}>
              <div className="side frontside">
                {!identityFiles.front && !frontDropzone.isDragActive && (
                  <span>Carnet frontal</span>
                )}
                {!identityFiles.front && frontDropzone.isDragActive && (
                  <span>Suelta el archivo aqui</span>
                )}
                {identityFiles.front && (
                  <img src={identityFiles.front.path} alt="" />
                )}
                {identityFiles.front && (
                  <IconButton
                    className={classes.iconDelete}
                    onClick={(e) => {
                      e.preventDefault();
                      saveIdentityFile(undefined, "front");
                    }}
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                )}
              </div>
              <section className={classes.upDate}>
                <div className={classes.arrow}>
                  <img src={arrowTop} />
                </div>
                <div>Subir</div>
              </section>
              <input {...frontDropzone.getInputProps()} />
            </div>
            <div className="dropzone" {...backDropzone.getRootProps()}>
              <div className="side backside">
                {!identityFiles.back && !backDropzone.isDragActive && (
                  <span>Carnet trasero</span>
                )}
                {!identityFiles.back && backDropzone.isDragActive && (
                  <span>Suelta el archivo aqui</span>
                )}
                {identityFiles.back && (
                  <img src={identityFiles.back.path} alt="" />
                )}
                {identityFiles.back && (
                  <IconButton
                    className={classes.iconDelete}
                    onClick={(e) => {
                      e.preventDefault();
                      saveIdentityFile(undefined, "back");
                    }}
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                )}
              </div>
              <section className={classes.upDate}>
                <div className={classes.arrow}>
                  <img src={arrowTop} />
                </div>
                <div>Subir</div>
              </section>
              <input {...backDropzone.getInputProps()} />
            </div>
          </div>
        )}

        <div>
          <div
            className={`${classes.form} ${isNotMission && classes.notMission}`}
          >
            <div className="checkboxes-title">
              <div className="checkboxes-title__option">No</div>
              <div className="checkboxes-title__option">Si</div>
            </div>
            <CheckboxInput
              className={classes.CheckboxInput}
              name="isUSperson"
              checked={isUSPerson}
              onChange={(e) => setIsUSPerson(e.target.checked ? true : false)}
            >
              <strong>Soy ciudadano norteamericano</strong> (US Person), según
              la ley FACTA (EEUU)
            </CheckboxInput>
            <CheckboxInput
              className={classes.CheckboxInput}
              name="isPEP"
              checked={isPEP}
              onChange={(e) => setIsPEP(e.target.checked ? true : false)}
            >
              He sido o{" "}
              <u onClick={openWindowPEP}>
                <strong>soy una Persona Expuesta Políticamente</strong>
              </u>{" "}
              o poseo una relación con alguna de ellas
            </CheckboxInput>
            <CheckboxInput
              className={classes.CheckboxInput}
              name="isQualifiedInvestor"
              checked={isQualifiedInvestor}
              onChange={(e) =>
                setIsQualifiedInvestor(e.target.checked ? true : false)
              }
            >
              <u onClick={openWindowisQualifiedInvestor}>
                <strong>Soy Inversionista Calificado</strong>
              </u>{" "}
              según lo establecido en la NCG Nº216 de la CMF (declaración
              jurada)
            </CheckboxInput>
            <CheckboxInput
              className={classes.CheckboxInput}
              name="hasAGFrelation"
              checked={hasAGFrelation}
              onChange={(e) =>
                setHasAGFrelation(e.target.checked ? true : false)
              }
            >
              ¿Estás contratado/a o afiliado/a a una bolsa de valores, corredora
              o empresa FINRA?
            </CheckboxInput>
            <CheckboxInput
              className={classes.CheckboxInput}
              name="hasEnterpriseRelation"
              checked={hasEnterpriseRelation}
              onChange={(e) =>
                setHasEnterpriseRelation(e.target.checked ? true : false)
              }
            >
              ¿Eres director, gerente, controlas o tienes más del 10% de las
              acciones de una empresa transada públicamente?
            </CheckboxInput>
            <CheckboxInput
              className={classes.CheckboxInput}
              name="agreement"
              checked={agreement}
              onChange={(e) => setAgreement(e.target.checked ? true : false)}
              hideFalse
            >
              Declaro haber leído y aceptado el{" "}
              <u onClick={generateMandatoFile}>
                <strong>Mandato</strong>
              </u>{" "}
              para que Rokin transmita mis órdenes y el{" "}
              <u onClick={generateContractFile}>
                <strong>Contrato</strong>
              </u>{" "}
              para que Vector opere mis transacciones
            </CheckboxInput>
          </div>
          {!isNotMission && (
            <div className={classes.button}>
              <ButtonPrimary
                text={buttonText}
                onClick={() => [
                  handleClick(),
                  createVectorAccount(true, client),
                ]}
                disabled={isDisabled}
                secondaryVersion
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    position: "relative",
    gap: "4rem",
    [theme.breakpoints.down(1136)]: {
      flexDirection: "column",
    },
  },
  notMissionContainer: {
    flexDirection: "column",
    marginTop: "2rem",
  },
  updateButton: {
    color: theme.palette.text.secondary,
    textDecoration: "underline",
    cursor: "pointer",
    opacity: 0.5,
    margin: "1rem",
    display: "flex",
    marginBottom: "-2rem",
    justifyContent: "space-around",
    [theme.breakpoints.down(1241)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  urlsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down(1241)]: {
      flexDirection: "column",
      height: "5rem",
      alignItems: "center",
    },

    "& .section": {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.secondary,
      width: "14rem",
      justifyContent: "space-evenly",
      "& img": {
        margin: "0 0.5rem",
        width: "1.5rem",
      },
    },
  },
  boxes: {
    width: 350,
    display: "grid",
    flexDirection: "column",
    justifyContent: "center",
    padding: "24px 0",
    "& > .dropzone": {
      display: "flex",
      justifyContent: "center",
      "& > .hide": {
        display: "none",
      },
      "& > .side": {
        width: 248,
        height: 153,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `3px dashed ${theme.palette.primary.dark}`,
        boxShadow: "4px 4px 12px 0 rgba(0, 0, 0, 0.75)",
        borderRadius: 8,
        opacity: 0.8,
        position: "relative",
        cursor: "pointer",
        [theme.breakpoints.down(740)]: {
          width: 200,
        },
        "& > span": {
          fontSize: 20,
          fontWeight: "bold",
          width: 69,
          textAlign: "center",
        },
        "& > img": {
          borderRadius: 8,
          objectFit: "cover",
          height: "100%",
          width: "100%",
        },
        "& >.MuiIconButton-root": {
          zIndex: 2,
          position: "absolute",
          top: 5,
          right: 5,
          margin: 0,
          color: "black",
          padding: 8,
          "& .MuiSvgIcon-root": {
            width: 22,
            height: 22,
          },
        },
      },
      "& > .frontside": {},
      "& > .backside": {},
    },
    [theme.breakpoints.down(1136)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "normal",
      gap: "2rem",
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      "& > .dropzone": {
        "& > .side": {
          width: "100%",
        },
        "& > .frontside": {
          marginRight: "0px !important",
          marginBottom: 40,
        },
        "& > .backside": {
          marginLeft: "0px !important",
        },
      },
    },
  },
  notMissionBoxes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    flexWrap: "wrap",
  },
  iconDelete: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "black",
  },
  form: {
    marginRight: "3rem",
    display: "grid",
    width: 720,
    gap: "0.75rem",
    borderRadius: 8,
    padding: 28,
    border: `2px solid ${theme.palette.grey[300]}`,
    "& div": {
      alignItems: "start",
      "&>span": {
        padding: "2px 9px",
      },
    },
    "& > .checkboxes-title": {
      gridColumn: 1 / 3,
      color: "#666666",
      fontSize: 16,
      fontWeight: "bold",
      display: "flex",
      "& > .checkboxes-title__option": {
        width: 42,
        textAlign: "center",
      },
    },
    "& > .checkbox-input:last-child": {
      marginTop: 60,
    },
    [theme.breakpoints.down(1136)]: {
      width: "100%",
    },
    [theme.breakpoints.down(600)]: {
      border: "none",
      margin: 0,
      padding: 0,
      "& > .checkbox-input": {
        fontSize: 12,
        margin: "10px 0px",
      },
    },
  },
  notMission: {
    width: "100% !important",
  },
  CheckboxInput: {
    color: theme.palette.primary.dark,
    "& span": {
      color: theme.palette.primary.dark,
    },
    "& >span:last-child": {
      color: theme.palette.text.primary,
    },
  },
  arrow: {
    width: 56,
    background: "white",
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
  },
  upDate: {
    marginLeft: 15,
    "& div:last-child": {
      fontSize: 14,
      textAlign: "center",
      color: theme.palette.text.primary,
      marginTop: 10,
    },
  },
  button: {
    marginTop: 40,
    marginRight: "3rem",
    display: "flex",
    justifyContent: "end",
    marginBottom: 30,
    [theme.breakpoints.down(1136)]: {
      marginRight: 0,
      "& button": {
        height: 52,
        minWidth: "100%",
      },
    },
  },
}));
const LINKS: Record<LinkType, string> = {
  [LinkType.QUALIFIED_INVESTOR]:
    "https://www.cmfchile.cl/mascerca/601/w3-article-1376.html",
  [LinkType.PEP]: "https://www.uaf.cl/legislacion/politica.aspx",
};
