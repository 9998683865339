const TARGET_ICON =
  "https://storage.googleapis.com/rokinapp-images/icons/target_icon.svg";
const EYE_ICON_SECUNDARY =
  "https://storage.googleapis.com/rokinapp-images/icons/eye_icon.svg";
const ASTRONAUTA_IZQ =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/myAccount/astronautaLupa_izquierda.svg";

const LIGHTBULB = "https://storage.googleapis.com/rokinapp-images/myAccount/lightbulb_icon.svg";

export { TARGET_ICON, EYE_ICON_SECUNDARY, ASTRONAUTA_IZQ, LIGHTBULB };
