import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  GoalSelector,
  InvestPeriodRangeSelector,
  NumericInput,
  TextInput,
} from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { GoalsContext } from "@context";

interface EditGoalProps {
  buttonContinue: string;
  title: string;
  secondTitle?: string;
  goal?: number;
  goalId?: number;
  goalPeriod?: number;
  goalType?: string;
}

export const EditGoal: React.FC<EditGoalProps> = ({
  buttonContinue,
  title,
  secondTitle,
  goal,
  goalId,
  goalPeriod,
  goalType,
}) => {
  const classes = useStyles();
  const [isProfile, setProfile] = useState<string>();
  const [newName, setNewName] = useState<string>(undefined);
  const [newPeriod, setNewPeriod] = useState<number>(0);
  const [newGoal, setNewGoal] = useState<number>(0);
  const { updateGoal } = useContext(GoalsContext);

  useEffect(() => {
    setProfile(goalType);
    setNewGoal(goal);
    if (goalPeriod) {
      setNewPeriod(goalPeriod);
    }
    setNewName(secondTitle);
  }, [goalType]);

  const GOALS_OPTIONS = [
    "Ahorrar",
    "Pagar deudas",
    "Mejorar mi jubilación",
    "Viajar",
    "Comprar una casa",
    "Fondo de emergencia",
    "Otro",
  ].map((r) => ({
    name: r,
    value: r,
  }));

  const handleClickUpdate = () => {
    try {
      updateGoal(goalId, {
        type: isProfile,
        period: newPeriod,
        name: newName,
        goal: newGoal,
      });
      navigate(Route.investments + "/?#objectives");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className={classes.children}>
        <section className={classes.container}>
          <div className={classes.goal}>
            <section>
              <div className={classes.headerLayout2}>
                <div className={classes.txts}>
                  <span className={classes.title}>
                    {title}
                    {secondTitle}
                  </span>{" "}
                  <span className={classes.subtitle}>Quiero...</span>
                </div>
              </div>
            </section>
            <GoalSelector
              options={GOALS_OPTIONS}
              value={`${isProfile}`}
              onChange={(e) => {
                setProfile(e.target.value);
              }}
            />
          </div>
          <div className={classes.titleReact}>
            <section>
              <div className={classes.headerLayout2}>
                <div className={classes.txts}>
                  <span className={classes.title}>Nombre de tu objetivo</span>{" "}
                  <span className={classes.subtitle}>
                    Ponle nombre a tu objetivo
                  </span>
                </div>
              </div>
            </section>
            <TextInput
              className={classes.textInput}
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className={classes.plazo}>
            <section>
              <div className={classes.headerLayout2}>
                <div className={classes.txts}>
                  <span className={classes.title}>Plazo </span>{" "}
                  <span className={classes.subtitle}>
                    En cuánto tiempo quieres lograr esta meta
                  </span>
                </div>
              </div>
            </section>
            <InvestPeriodRangeSelector
              className={classes.investPeriod}
              name="goalTerm"
              onChange={(e) => setNewPeriod(e.target.value)}
              value={newPeriod}
              label="Quiero lograrlo en:"
            />
          </div>
          <div className={classes.meta}>
            <section>
              <div className={classes.headerLayout2}>
                <div className={classes.txts}>
                  <span className={classes.title}>Meta</span>{" "}
                  <span className={classes.subtitle}>(Opcional)</span>
                </div>
              </div>
            </section>
            <NumericInput
              className={classes.textInput}
              name="goalAmount"
              value={newGoal}
              onChange={(e) => setNewGoal(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <ButtonOutlined
              className="outlined"
              onClick={() => navigate(Route.investments)}
              text={"Cancelar"}
            />
            <ButtonPrimary
              className="primary"
              text={buttonContinue}
              onClick={handleClickUpdate}
            />
          </div>
        </section>
      </div>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  children: {},
  txts: {
    paddingTop: "1rem",
    "& span": {
      display: "block",
    },
  },
  headerLayout2: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    paddingTop: 20,
    marginBottom: "2rem",
    "& img": {
      width: 140,
      height: 140,
    },
    [theme.breakpoints.down(1000)]: {
      marginBottom: 0,
    },
  },
  container: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "40% 40%",
    gridTemplateAreas: "'goal plazo' 'react meta' 'found buttons'",
    "& input": {
      width: 400,
    },
    [theme.breakpoints.down(1280)]: {
      padding: "25px",
      gap: "54px",
    },
    [theme.breakpoints.down(1000)]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  goal: {
    gridArea: "goal",
    "&>div": {
      width: 428,
    },
    [theme.breakpoints.down(1000)]: {
      "&>div": {
        width: "100%",
      },
    },
  },
  titleReact: {
    gridArea: "react",
  },
  plazo: {
    gridArea: "plazo",
  },
  meta: {
    gridArea: "meta",
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 24,
    fontWeight: "bold",
    color: "#393e44",
    display: "block",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    display: "block",
    color: theme.palette.text.secondary,
  },
  buttons: {
    gridArea: "buttons",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    paddingTop: "4rem",
    width: 430,
    "& .outlined, .primary": {
      width: 185,
    },
    "& .primary": {
      marginLeft: 20,
    },
    [theme.breakpoints.down(1000)]: {
      width: "100%",
    },
  },
  investPeriod: {
    maxWidth: 428,
  },
  textInput: {
    [theme.breakpoints.down(1000)]: {
      width: "100%",
    },
  },
}));
