import React from "react";
import { makeStyles, Theme, IconButton } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { numberMission, home } from "images/onBoarding";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

interface LayoutNotMissionProps {
  children: React.ReactNode;
  className?: string;
  styleFooter?: string;
  icon?: string;
  title: string;
  subtitle?: string;
  handleBack: () => void;
}

export const LayoutNotMissionMobile: React.FC<LayoutNotMissionProps> = ({
  className,
  children,
  icon,
  title,
  subtitle,
  handleBack,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className || ""} ${classes.container}`}>
      <header className={classes.header}>
        <IconButton onClick={handleBack}>
          <ArrowBackIosRoundedIcon className={classes.goBackIcon} />
        </IconButton>
        <div className={classes.title}>{title}</div>
        <div onClick={() => navigate(Route.investments)}>
          <img className={classes.img} src={home} />
        </div>
      </header>
      <div className={classes.subtitle}>{subtitle}</div>
      <div className={classes.children}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
    "& footer": {
      marginTop: "2rem",
    },
  },
  children: {
    padding: "0 1.5625rem 0 1.5625rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 90,
    alignItems: "center",
    width: "100%",
    backgroundColor: "#F4F3F3",
    boxShadow: "8px 8px 12px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "0 0 30px 30px",
    "& span": {
      textTransform: "inherit",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: 20,
  },
  img: {
    width: 30,
    marginRight: 20,
  },
  goBackIcon: {
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
  },
  subtitle: {
    margin: "15px 10px 0 10px",
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: "1.15rem",
    color:theme.palette.text.secondary,
    textAlign: "center",
  },
}));
