import React, { useContext, useState, useEffect } from "react";
import { Dialog, useMediaQuery } from "@material-ui/core";
import { AppContext, NewInvestContext, ProfileContext } from "@context";
import {
  ButtonPrimary,
  BankSelector,
  BankAccountTypeSelector,
  BankAccountNumberInput,
} from "@components";
import {
  makeStyles,
  IconButton,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { WithdrawIcon } from "@images";
import { navigate } from "gatsby";
import { CongratsModalType, Route } from "@interfaces";
import { Select, TextInput, NumericInput } from "@components";
import { useGoals, usePortfolios, useUserInvestments } from "@apollo";
import { useForm } from "@hooks";

interface WithdrawFundsModalProps {
  open: boolean;
  onClose: () => void;
  selectedGoal: number;
}

export const WithdrawFundsModal: React.FC<WithdrawFundsModalProps> = ({
  open,
  onClose,
  selectedGoal,
}) => {
  const classes = useStyles();
  const {
    form,
    onChange,
    onErrorChange,
    onPageBackClick,
    onContinueClick,
    isLastPage,
    pageStep,
    anyErrorOnStep,
  } = useContext(NewInvestContext);
  const [formBank, , , handleChange] = useForm({});
  const { portfolios, loading: loadingPortfolios } = usePortfolios();
  const { investments, loading: loadingInvestments } = useUserInvestments();
  const { goals } = useGoals();
  const [myGoal, setMyGoal] = useState<string>("Objetivo");
  const { isBankDataCompleted } = useContext(ProfileContext);
  const [isSecond, setIsSecond] = useState<boolean>(false);
  const [fundValue, setFundValue] = useState<string>("null");
  const [radioSelected, setRadioSelected] = useState<string>("fraction");
  const [currentFundValue, setCurrentValue] = useState<number>(0);
  const [isNumericInput, setIsNumercInput] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>("Aceptar");
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);
  const [fundsOptions, setFundsOptions] = useState([
    { value: "null", name: "No hay Fondos", seireId: 0, currentValue: 0 },
  ]);

  useEffect(() => {
    if (isSecond) {
      if (form.amount == 0) {
        setInvalidAmount(true);
      } else {
        setInvalidAmount(false);
      }
    }
  }, [form.amount]);

  useEffect(() => {
    const goal = goals.filter((obj) => obj.id === selectedGoal);
    form.goal = selectedGoal;

    if (goal[0] !== undefined) {
      setMyGoal(goal[0].name);
    }
  }, [open, selectedGoal]);

  function getFundsOptions(data) {
    const fundsArray = [];

    if (data.length > 0) {
      data.forEach((element) => {
        if (element.__typename == "Portfolio") {
          fundsArray.push({
            value: element.id,
            name: element.name,
            serieId: " ",
            currentValue: element.currentValue,
            type: "Portfolio",
          });
          setFundValue(element.id);
        }
        if (element.__typename == "UserInvestment") {
          fundsArray.push({
            value: element.id,
            name: element.fund.name + " - " + element.serie.name,
            serieId: element.serie.id,
            currentValue: element.currentValue,
            type: "UserInvestment",
          });
          setFundValue(element.id);
        }
      });
    }

    return fundsArray;
  }

  useEffect(() => {
    setFundsOptions(
      getFundsOptions(
        [...portfolios, ...investments].filter(
          (obj) => obj.goalId === selectedGoal
        )
      )
    );
  }, [selectedGoal]);

  useEffect(() => {
    if (fundValue !== "null" || fundValue !== undefined) {
      const fundOptionsFiltered = fundsOptions.filter(
        (obj) => obj.value === fundValue
      )[0];
      setCurrentValue(fundOptionsFiltered?.currentValue);
      if (fundOptionsFiltered?.type == "Portfolio") {
        form.portfolioId = fundValue;
        form.fund = null;
        form.serieId = null;
      } else {
        form.fund = fundValue;
        form.serie = fundOptionsFiltered?.serieId;
        form.portfolioId = null;
      }
    }
  }, [fundValue]);

  useEffect(() => {
    if (radioSelected == "all") {
      form.amount = currentFundValue;
      setIsNumercInput(true);
    } else {
      form.amount = 0;
      setIsNumercInput(false);
    }
  }, [radioSelected]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioSelected((event.target as HTMLInputElement).value);
  };

  const handleClick = () => {
    onContinueClick();
    setButtonText("Retirar");
    setIsSecond(true);
    setInvalidAmount(true);
    if (isSecond) {
      onClose();
      setIsSecond(false);
      setInvalidAmount(false);
    }
  };

  const handleOnClose = () => {
    onClose();
    setButtonText("Aceptar");
    setIsSecond(false);
    setInvalidAmount(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleOnClose} className={classes.Dialog}>
        <IconButton onClick={handleOnClose} className={classes.CloseIcon}>
          <CloseIcon />
        </IconButton>
        <div className={classes.Modal}>
          <div className={classes.info}>
            <div className={classes.titulo}>
              <div className={classes.numberInfo}>
                <img src={WithdrawIcon} className={classes.numberImg} />
              </div>
              <div className={classes.text1}>Retirar Fondos</div>
            </div>
            <div className={classes.linea}></div>
            {!isSecond && (
              <div className={classes.savingOnText}>
                Confirma los datos de tu cuenta bancaria
              </div>
            )}
            {isSecond && (
              <div className={classes.savingOnText}>Rescataremos de:</div>
            )}
            <div className={classes.savingOn}>
              {!isSecond && (
                <>
                  <BankSelector
                    className={classes.selectors}
                    value={formBank.bank}
                    onChange={handleChange}
                  />
                  <BankAccountTypeSelector
                    className={classes.selectors}
                    value={formBank.accountType}
                    onChange={handleChange}
                  />
                  <BankAccountNumberInput
                    className={classes.selectors}
                    value={formBank.accountNumber}
                    onChange={handleChange}
                  />
                </>
              )}
              {isSecond && (
                <>
                  <Select
                    className="title"
                    name="sort"
                    value={fundValue}
                    onChange={(e) => setFundValue(e.target.value)}
                    size={"large"}
                    options={fundsOptions}
                  ></Select>
                  <div className={classes.radio}>
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={radioSelected}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="fraction"
                          control={<Radio />}
                          label="Una parte"
                        />
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label={`Todo ($ ${currentFundValue.toLocaleString(
                            "es"
                          )})`}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className={classes.amount}>
                    <span>Monto a rescatar:</span>
                    <NumericInput
                      name="amount"
                      onChange={onChange}
                      disabled={isNumericInput}
                      value={form.amount}
                    />
                  </div>
                  <div className={classes.bottomText}>
                    <strong>
                      OJO, te llegará el monto aproximado de $
                      {form.amount.toLocaleString("es")}, este puede subir o
                      bajar ligeramente en los días que demore el retiro
                    </strong>
                  </div>
                </>
              )}
              <div className={classes.amountOf}>
                <div className={classes.targetText}>
                  Estás retirando de: <div>{myGoal}</div>
                </div>
                <ButtonPrimary
                  className={classes.button}
                  disabled={
                    !isBankDataCompleted || anyErrorOnStep || invalidAmount
                  }
                  secondaryVersion
                  text={buttonText}
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    Dialog: {
      "& >div >div": {
        borderRadius: "35px",
        opacity: "90%",
        maxWidth: 900,
      },
    },
    Modal: {
      width: 800,
      height: 520,
      flexGrow: 0,
      padding: "22px 44px 22px 44px",
      borderRadius: 35,
      border: "1px solid #ffffff",
      [theme.breakpoints.down(700)]: {
        width: 500,
      },
      [theme.breakpoints.down(600)]: {
        width: 352,
        padding: "10px 15px",
      },
    },
    info: {
      display: "grid",
    },
    titulo: {
      display: "flex",
    },
    CloseIcon: {
      position: "absolute",
      right: 20,
      top: 20,
    },
    savingOnText: {
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: 600,
      padding: "4px 0px 5px 0",
    },
    numberInfo: {
      position: "relative",
      margin: "-20px -15px -10px -15px",
      "& >span": {
        position: "absolute",
        zIndex: 3,
        top: 37,
        left: 51,
        color: "#4619b6",
        fontSize: "1.5rem",
        fontWeight: "bold",
        fontFamily: "montserrat",
      },
    },
    numberImg: {
      width: "110px",
      height: "auto",
    },
    text1: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#393E44",
      marginTop: "20px",
    },
    linea: {
      width: "100%",
      height: 2,
      flexGrow: 0,
      backgroundColor: "#fff",
    },
    button: {
      width: 204,
      height: 73,
      right: 15,
      fontSize: "1.5rem",
      [theme.breakpoints.down(600)]: {
        width: 320,
        height: 50,
        display: "flex",
        justifyContent: "center",
        marginLeft: "1rem",
      },
    },
    savingOn: {
      display: "grid",
      gap: "6%",
      [theme.breakpoints.down(600)]: {
        gap: "1rem",
      },
    },
    dropdownClass: {
      width: "100%",
      boxShadow: "4px 4px 8px -3px rgb(0 0 0 / 60%)",
      height: 44,
      top: 10,
      [theme.breakpoints.down(600)]: {
        width: "96%",
      },
    },
    targetText: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 600,
      color: "#393E44",
      [theme.breakpoints.down(600)]: {
        textAlign: "center",
      },
      "& >div": {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#393E44",
      },
      "& >span": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontWeight: "normal",
        color: "#008296",
      },
    },
    amountOf: {
      display: "flex",
      paddingTop: 20,
      gap: "33%",
      [theme.breakpoints.down(600)]: {
        display: "grid",
        gap: "1rem",
      },
    },
    amountText: {
      boxShadow: "4px 4px 8px -3px rgb(0 0 0 / 60%)",
      top: "12px",
      width: "100%",
      [theme.breakpoints.down(600)]: {
        width: "97%",
      },
    },
    radio: {
      "& div, div": {
        flexDirection: "initial",
        "& span": {
          fontSize: 18,
          fontFamily: "Montserrat",
        },
        "& span:last-child": {
          marginRight: "5rem",
        },
        "& svg": {
          color: theme.palette.primary.main,
        },
      },
    },
    amount: {
      display: "inherit",
      "& span": {
        fontSize: 16,
        fontWeight: "bold",
      },
      "& >div": {
        width: "100%",
      },
    },
    bottomText: {
      fontSize: 12,
    },
    selectors: {
      marginBottom: 21.5,
    },
  }),
  { index: 1 }
);
