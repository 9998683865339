import { FinerioTransaction } from "@interfaces";
// import { getFinerioAccounts } from "@apollo";
import _ from "lodash";

interface responseDto {
  date: string;
  categories: [];
  income: number;
  expense: number;
  monthBalance: number;
}

export function finerioTransactionsByCategory(
  finerioTransactions: FinerioTransaction[]
) {
  // const { finerioAccounts } = getFinerioAccounts();
  let response: responseDto[] = [];
  const removeItems = [
    "pago pesos tef",
    "total pagos cuenta",
    "pago tarjeta credito",
    "pago tarjeta cmr",
    "pago pesos tef pago normal",
    "cargo pago tc",
    "cargo por pago tc",
    "cargo pago tarjeta credito",
    "pago tercero pago normal",
  ];

  //TO-DO: Add category savings, this category should not be added to charges and should ne shown as another parent category (income, expenses, savings)
  //Deleting ["pago pesos tef", "total pagos cuenta", "pago tarjeta credito"]
  const allTransactions = finerioTransactions.filter((value) => {
    return !removeItems.includes(value.description.toLowerCase());
  });

  const groupedByMonth = _.groupBy(allTransactions, function (item) {
    let date = item.date.toISOString().split("T")[0];
    return date.substring(0, 7);
  });

  Object.entries(groupedByMonth).forEach((element) => {
    let byMonth: any = [];
    let totalIncomePerMonth: number;
    let totalExpensePerMonth: number;

    const transactionsFromMonthByCategory = _.groupBy(element[1], (tx) => {
      //console.log("🚀 « tx", tx) // to see if the subcategoryId exists
      if (tx.newSubcategory === null) {
        return tx.subcategoryId.FinerioCategory?.name;
      } else {
        return tx.newSubcategory.FinerioCategory?.name;
      }
    });

    Object.entries(transactionsFromMonthByCategory).forEach((element2) => {
      let categoryName = element2[0];
      let transactions = element2[1];
      let income = 0;
      let expense = 0;

      const groupedByCharge = _.groupBy(transactions, (tx) => tx.isCharge);

      Object.entries(groupedByCharge).forEach((element3) => {
        let type = element3[0] === "true";
        let transactionsGrouped = element3[1];

        // let account = finerioAccounts.find((obj) => obj.accountId === transactionsGrouped[0].accountId)

        if (!type) {
          income = transactionsGrouped
            .map((item) => item.amount)
            .reduce((a, b) => a + b);
        } else {
          expense = transactionsGrouped
            .map((item) => item.amount)
            .reduce((a, b) => a + b);
        }
      });

      byMonth.push({
        name: categoryName,
        transactions: transactions,
        income: income,
        expense: expense,
        icon: element2[1][0].newSubcategory
          ? element2[1][0].newSubcategory.FinerioCategory.icon
          : element2[1][0].subcategoryId.FinerioCategory.icon,
      });
    });

    totalIncomePerMonth = byMonth
      .map((item) => item.income)
      .reduce((a, b) => a + b);
    totalExpensePerMonth = byMonth
      .map((item) => item.expense)
      .reduce((a, b) => a + b);

    let formattedDate = element[0].split("-");

    response.push({
      date: `${formattedDate[1]}/01/${formattedDate[0]}`,
      categories: byMonth,
      income: totalIncomePerMonth,
      expense: totalExpensePerMonth,
      monthBalance: totalIncomePerMonth - totalExpensePerMonth,
    });
  });
  return response;
}
