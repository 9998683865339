import { ApolloClient, gql } from "@apollo/client";

const CREATE_USER_SUBSCRIPTION = gql`
  mutation Mutation($params: CreateUserSubscriptionParams) {
    createUserSubscription(params: $params)
  }
`;

type CreateUserSubscriptionParams = {
  paid: boolean;
  planId: number;
};

export async function createUserSubscription(
  client: ApolloClient<any>,
  params: CreateUserSubscriptionParams
): Promise<boolean> {
  const { data } = await client.mutate({
    mutation: CREATE_USER_SUBSCRIPTION,
    variables: { params: params },
  });

  return data;
}
