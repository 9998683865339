import React from "react";
import { Select } from "@components";
import { CommonSelectorProps, DROP_RESPONSE_OPTIONS } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}

export const InvestmentDropResponseSelector: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-investmentDropResponse-selector`}
      name={name || "investmentDropResponse"}
      label={label || "Si mi inversión cae un 25%"}
      options={DROP_RESPONSE_OPTIONS}
      {...props}
    />
  );
};
