import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import {
  ButtonPrimary,
  InvestmentExperienceSelector,
  InvestmentRiskSelector,
  InvestTermSelector,
  CurrentInvestingSelector,
  InvestmentConcernSelector,
  InvestmentDropResponseSelector,
} from "@components";
import { calculateSuggestedProfile } from "@utils";

interface DiscoverProfileProps {
  setShowDiscoverProfile: () => void;
  setInvestorProfile: () => void;
  form: any;
  isNotMission?: boolean;
}

export const DiscoverProfile: React.FC<DiscoverProfileProps> = ({
  setShowDiscoverProfile,
  setInvestorProfile,
  form,
  isNotMission,
}) => {
  const classes = useStyles();
  const [investTerm, setInvestTerm] = useState<string>(
    form.investmentTerm ? form.investmentTerm : ""
  );
  const [risk, setRisk] = useState<string>(form.risk ? form.risk : "");
  const [experience, setExperience] = useState<string>(
    form.investmentExperience ? form.investmentExperience : ""
  );
  const [concern, setConcern] = useState<string>(
    form.investmentConcern ? form.investmentConcern : ""
  );
  const [current, setCurrent] = useState<string>(
    form.currentInvesting ? form.currentInvesting : ""
  );
  const [drop25, setDrop25] = useState<string>(
    form.investmentDropResponse ? form.investmentDropResponse : ""
  );

  const handleDiscoverClick = () => {
    const result = calculateSuggestedProfile(investTerm, {
      risk: risk,
      investmentExperience: experience,
      investmentConcern: concern,
      currentInvesting: current,
      investmentDropResponse: drop25,
    });
    form.risk = risk;
    form.investmentExperience = experience;
    form.investmentConcern = concern;
    form.currentInvesting = current;
    form.investmentDropResponse = drop25;
    form.investmentTerm = investTerm;
    setInvestorProfile(result?.profile);
    setShowDiscoverProfile(false);
  };

  return (
    <>
      <section className={classes.containerData}>
        <div
          className={`${classes.children} ${
            isNotMission && classes.notMissionChildren
          }`}
        >
          <div className={classes.selectors}>
            <InvestTermSelector
              name="investmentTerm"
              onChange={(e) => setInvestTerm(e.target.value)}
              value={investTerm}
              label="Retiraré dinero en un plazo de"
            />
            <InvestmentRiskSelector
              name="investmentRisk"
              onChange={(e) => setRisk(e.target.value)}
              value={risk}
              label="Si invierto 1 millón"
            />
            <InvestmentExperienceSelector
              className={classes.input}
              name="investmentExperience"
              onChange={(e) => setExperience(e.target.value)}
              value={experience}
              label="Mi experiencia invirtiendo es:"
            />
          </div>
          <div className={classes.selectors}>
            <InvestmentConcernSelector
              name="investmentConcern"
              onChange={(e) => setConcern(e.target.value)}
              value={concern}
              label="Cuándo invierto:"
            />
            <CurrentInvestingSelector
              name="currentInvesting"
              onChange={(e) => setCurrent(e.target.value)}
              value={current}
              label="Actualmente invierto en:"
            />
            <InvestmentDropResponseSelector
              name="investmentDropResponse"
              onChange={(e) => setDrop25(e.target.value)}
              value={drop25}
              label="Si mi inversión cae un 25%, tu:"
            />
          </div>
        </div>
        <div className={classes.buttonSection}>
          <ButtonPrimary
            text="Continuar"
            onClick={handleDiscoverClick}
            className={`${classes.buttonPrimary} ${
              isNotMission && classes.notMissionButton
            }`}
            secondaryVersion={!isNotMission}
          />
        </div>
      </section>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  children: {
    marginTop: "3rem",
    display: "flex",
    gap: "5rem",
    [theme.breakpoints.down(1000)]: {
      flexDirection: "column",
      gap: 0,
      marginTop: "2rem",
    },
  },
  notMissionChildren: {
    flexWrap: "wrap",
    gap: 0,
    justifyContent: "space-around",

    "& >div": {
      marginLeft: "0",
    },
  },
  selectors: {
    marginLeft: 30,
    marginTop: "3rem",
    display: "grid",
    "& >div": {
      width: 400,
      margin: "1rem 0",
    },
    [theme.breakpoints.down(1000)]: {
      marginLeft: 0,
      marginTop: 0,
      "& >div": {
        width: "100%",
        margin: "1rem 0",
      },
    },
  },
  containerData: {
    display: "block",
  },
  input: {
    width: 335,
    margin: "10px auto",
  },
  buttonPrimary: {
    width: 185,
    margin: "0 auto",
    marginTop: "3rem",
    [theme.breakpoints.down(1000)]: {
      width: "100%",
      height: 52,
      marginTop: "2rem",
    },
  },
  buttonSection: {
    display: "flex",
  },
}));
