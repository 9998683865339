import React, {
  useMemo,
  useState,
  useContext,
  createContext,
  useCallback,
} from "react";
import moment from "moment-timezone";
import {} from "@services";
import {} from "../../services/firebase";
import {
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  User,
  OAuthProvider,
} from "firebase/auth";
import { navigate } from "gatsby";
import { Route, ProviderType, RegisterForm } from "@interfaces";
import { createUser } from "@apollo";
import { capitalize } from "lodash";
import { useApolloClient } from "@apollo/client";
// Typescript error on SDK
type FixedUser = User & { accessToken: string };

interface StandardCallbacks {
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface BaseCreateUser extends StandardCallbacks {
  investmentTerm: RegisterForm["investmentTerm"];
  risk: RegisterForm["investmentRisk"];
  investmentExperience: RegisterForm["investmentExperience"];
  investmentConcern: RegisterForm["investmentConcern"];
  currentInvesting: RegisterForm["currentInvesting"];
  investmentDropResponse: RegisterForm["investmentDropResponse"];
  manuallySelectedInvestorProfile: boolean;
}

interface RegisterParams extends BaseCreateUser {
  name: string;
  investorProfile: string;
  email: string;
  password: string;
}
interface LoginParams {
  email: string;
  password: string;
  onSuccess?: () => void;
  onFailure?: () => void;
}
interface LoginReturn {
  name: string;
  email: string;
  accessToken: string;
}
type LoginWithGoogleParams = StandardCallbacks;
interface RegisterWithGoogleParams extends BaseCreateUser {
  name: string;
  investorProfile?: string;
}

interface CreateUserAfterGoogleParams extends BaseCreateUser {
  name: string;
  email: string;
  investorProfile: string;
}

interface AuthContextType {
  registerWithEmailPassword: (params: RegisterParams) => Promise<void>;
  registerWithGoogle: (params: RegisterWithGoogleParams) => Promise<void>;
  loginWithEmailPassword: (params: LoginParams) => Promise<void>;
  loginWithGoogle: (
    params?: LoginWithGoogleParams, appleProvider?:boolean
  ) => Promise<LoginReturn | void>;
  loginWithApple: (
    params?: LoginWithGoogleParams, appleProvider?:boolean
  ) => Promise<LoginReturn | void>;
  createUserAfterGoogle: (
    params: CreateUserAfterGoogleParams
  ) => Promise<boolean>;
  logout: () => void;
  tempAccToken: string;
}
export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider: React.FC = ({ children }) => {
  const client = useApolloClient();
  const [tempAccToken, setTempAccToken] = useState("");
  /**
   * Auth Logic
   */

  const onLoginNavigateOut = useCallback(() => {
    navigate(Route.finance);
  }, []);

  /* Función que se llama después de un login/registro exitoso
     que se encarga de guardar token en localStorage y rehacer las queries */
  const handleLoggedInUser = useCallback(
    (token: string, email: string | null) => {
      console.log(email, "email");
      console.log(token, "token");

      if (email) localStorage.setItem("lastEmail", email);
      if (token) localStorage.setItem("token", token);
    },
    []
  );

  /* Función que se llama después de un login/registro fallido (o logout)
     que se encarga de vaciar contextos, desloguearse de firebase,
     limpiar el localStorage y navegar al homepage */
  const handleLoggedOutUser = useCallback(async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
    } catch (error) {
      console.log(error);
    }
    client.cache.reset();
    client.clearStore();
    navigate("/");
  }, [client]);

  const registerWithEmailPassword = useCallback(
    async ({
      email,
      password,
      name,
      investorProfile,
      investmentTerm,
      risk,
      investmentExperience,
      investmentConcern,
      currentInvesting,
      investmentDropResponse,
      manuallySelectedInvestorProfile,
      onSuccess,
      onFailure,
    }: RegisterParams) => {
      const auth = getAuth();
      try {
        // Create entry at firebase auth
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const { accessToken } = userCredential.user as FixedUser;
        if (!accessToken) throw new Error("failed_google_register");
        // Create entry at database
        const newUser = await createUser(
          {
            name,
            email: email ?? "",
            investorProfile: investorProfile ? investorProfile : "",
            investmentTerm,
            risk,
            investmentExperience,
            investmentConcern,
            currentInvesting,
            investmentDropResponse,
            manuallySelectedInvestorProfile,
            password,
            provider: ProviderType.USER_PASSWORD,
          },
          client
        );
        if (!newUser) throw new Error("user_not_created");
        // Save token and change context var
        handleLoggedInUser(accessToken, email);
        onSuccess && onSuccess();
      } catch (error: any) {
        console.error({ errorCode: error?.code, errorMessage: error?.message });
        // handleLoggedOutUser();
        onFailure && onFailure();
      }
    },
    [client, handleLoggedInUser, handleLoggedOutUser]
  );

  const registerWithGoogle = useCallback(
    async ({
      name,
      investorProfile,
      investmentTerm,
      risk,
      investmentExperience,
      investmentConcern,
      currentInvesting,
      investmentDropResponse,
      manuallySelectedInvestorProfile,
      onSuccess,
      onFailure,
    }: RegisterWithGoogleParams) => {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      try {
        const userCredential = await signInWithPopup(auth, provider);
        const { email, accessToken } = userCredential.user as FixedUser;
        if (!accessToken || !email) throw new Error("Failed_google_login");

        const newUser = await createUser(
          {
            name: userCredential.user.displayName ?? name,
            email: email ?? "",
            investorProfile: investorProfile ? investorProfile : "",
            investmentTerm,
            risk,
            investmentExperience,
            investmentConcern,
            currentInvesting,
            investmentDropResponse,
            manuallySelectedInvestorProfile,
            provider: ProviderType.GOOGLE,
          },
          client
        );

        if (!newUser) throw new Error("user_not_created");
        // Save token and change context var
        handleLoggedInUser(accessToken, email);
        onSuccess && onSuccess();
      } catch (error: any) {
        console.error({
          email: error,
          errorCode: error?.code,
          errorMessage: error?.message,
        });
        handleLoggedOutUser();
        onFailure && onFailure();
      }
    },
    [client, handleLoggedInUser, handleLoggedOutUser]
  );

  const loginWithEmailPassword = useCallback(
    async ({ email, password, onSuccess, onFailure }: LoginParams) => {
      const auth = getAuth();
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user as FixedUser;
        const { accessToken } = user;
        handleLoggedInUser(accessToken, email);
        onSuccess && onSuccess();
        onLoginNavigateOut();
      } catch (error: any) {
        console.error({ errorCode: error?.code, errorMessage: error?.message });
        // handleLoggedOutUser();
        onFailure && onFailure();
      }
    },
    [handleLoggedInUser, onLoginNavigateOut, handleLoggedOutUser]
  );

  const loginWithGoogle = useCallback(
    async ({ onSuccess, onFailure }: LoginWithGoogleParams = {}, appleProvider = false) => {      
      let provider = null;
      if(appleProvider) {
        provider = new OAuthProvider('apple.com');
      } else {
        provider = new GoogleAuthProvider();
      }
      const auth = getAuth();
      try {
        //Se abre el modal de google
        const userCredential = await signInWithPopup(auth, provider);
        const {
          displayName,
          email,
          accessToken,
          metadata: { lastSignInTime, creationTime },
        } = userCredential.user as FixedUser;
        if (!accessToken || !email) throw new Error("Failed_google_login");
        // Determine if it's user first time logging in with google
        const isFirstTime =
          moment(lastSignInTime).diff(moment(creationTime)) < 1000;
        if (isFirstTime) {
          //Creating user if it is firstlogin
          const newUser = await createUser(
            {
              name: userCredential.user.displayName ?? name,
              email: email ?? "",
              investorProfile: "",
              provider: ProviderType.GOOGLE,
            },
            client
          );

          if (!newUser) throw new Error("user_not_created");
          // Save token and change context var
          handleLoggedInUser(accessToken, email);
          onSuccess && onSuccess();
          navigate(Route.producTour);
        } else {
          // Ya ha entrado antes, deberia tener cuenta
          onSuccess && onSuccess();
          handleLoggedInUser(accessToken, email);
          onLoginNavigateOut();
        }
      } catch (error: any) {
        console.error({ errorCode: error?.code, errorMessage: error?.message });
        //handleLoggedOutUser();
        onFailure && onFailure();
      }
    },
    [handleLoggedInUser, onLoginNavigateOut, handleLoggedOutUser, client]
  );

  /* Función que se llama después de completar el flujo de registro
     luego de haber seleccionado ingresar con google (por 1ra vez)
     y que se encarga de crear el usuario en BBDD */
  const createUserAfterGoogle = useCallback(
    async ({
      name,
      email,
      investorProfile,
      investmentTerm,
      risk,
      investmentExperience,
      investmentConcern,
      currentInvesting,
      investmentDropResponse,
      manuallySelectedInvestorProfile,
      onSuccess,
      onFailure,
    }: CreateUserAfterGoogleParams) => {
      try {
        // Create entry at database
        const newUser = await createUser(
          {
            name,
            email,
            investorProfile,
            provider: ProviderType.GOOGLE,
            investmentTerm,
            risk,
            investmentExperience,
            investmentConcern,
            currentInvesting,
            investmentDropResponse,
            manuallySelectedInvestorProfile,
          },
          client
        );
        if (!newUser) throw new Error("user_not_created");
        onSuccess && onSuccess();
        handleLoggedInUser(tempAccToken, email);
        setTempAccToken("");
        onLoginNavigateOut();
        return true;
      } catch (error) {
        console.error(error);
        onFailure && onFailure();
        return false;
      }
    },
    [
      client,
      tempAccToken,
      setTempAccToken,
      handleLoggedInUser,
      onLoginNavigateOut,
    ]
  );

  const logout = useCallback(() => {
    handleLoggedOutUser();
  }, [handleLoggedOutUser]);

  /**
   * Context data
   */
  const context = useMemo(
    () => ({
      registerWithEmailPassword,
      registerWithGoogle,
      loginWithEmailPassword,
      loginWithGoogle,
      createUserAfterGoogle,
      logout,
      tempAccToken,
      setTempAccToken,
    }),
    [
      registerWithEmailPassword,
      registerWithGoogle,
      loginWithEmailPassword,
      loginWithGoogle,
      createUserAfterGoogle,
      logout,
      tempAccToken,
      setTempAccToken,
    ]
  );

  return (
    <AuthContext.Provider value={context}>
      <>{children}</>
    </AuthContext.Provider>
  );
};
