import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ContactDialog,
  ContactDialogFinished,
  FeatureComingSoonDialog,
  CongratsModal,
  GoalModal,
  MissionsModal,
  NoGoalWarningModal,
  NoPersonalDataWarningModal,
  AddCompanyModal,
} from "@components";
import { CongratsModalType, FundWithSeries, Route } from "@interfaces";
import { LoadingContext, MissionsContext } from "@context";
import { useLocation } from "@reach/router";
import { useCurrentUser, useFundsWithSeries } from "@apollo";
import { CompleteMissionModal } from "@components/Modals/CompleteMissionModal";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css'
import { useAuth } from "@hooks";

interface AppContextType {
  fundsWithSeries: FundWithSeries[];
  // funds: Fund[];
  // series: Serie[];
  contactDialogOpen: boolean;
  openContactDialog: () => void;
  closeContactDialog: () => void;
  contactDialogFinishedOpen: boolean;
  openContactDgFinished: () => void;
  closeContactDgFinished: () => void;
  openFeatureDialog: () => void;
  closeFeatureDialog: () => void;
  lastPage: Route;
  congratsModalOpen: boolean; //This is congrats modal
  openCongratsModal: () => void; //This is congrats modal
  closeCongratsModal: () => void; //This is congrats modal
  goalModalOpen: boolean;
  openGoalModal: () => void;
  closeGoalModal: () => void;
  missionsModalOpen: boolean;
  openMissionsModal: () => void;
  closeMissionsModal: () => void;
  noGoalWarningModalOpen: boolean;
  openNoGoalWarningModal: () => void;
  closeNoGoalWarningModal: () => void;
  noPersonalDataWarningModalOpen: boolean;
  openNoPersonalDataWarningModal: () => void;
  closeNoPersonalDataWarningModal: () => void;
  addCompanyModalOpen: boolean;
  openAddCompanyModal: () => void;
  closeAddCompanyModal: () => void;
}

export const AppContext = createContext({} as AppContextType);

export const AppProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [lastPage, setLastPage] = useState(Route.index); // between Comparator and Investor Profile
  const { user: currentUser } = useCurrentUser();

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && currentUser) {
      LogRocket.init("b2fwm4/rokin");
      LogRocket.identify(currentUser?.id.toString(), {
        name: `${currentUser?.name} ${currentUser?.personalData.paternalSurname}`,
        email: currentUser?.email,
      });
      setupLogRocketReact(LogRocket);
    }
  }, [currentUser]);
  // Fetch AGF-fund-series tuples
  const { fundsWithSeries, loading: loadingAllFunds } = useFundsWithSeries();

  useEffect(
    () =>
      loadingAllFunds
        ? showLoader("Fetching Extended Funds", [
            Route.index,
            Route.index,
            Route.investorProfile,
          ])
        : hideLoader("Fetching Extended Funds"),
    [loadingAllFunds, showLoader, hideLoader]
  );

  useEffect(() => {
    if (
      location.pathname.includes(Route.investorProfile) ||
      location.pathname.includes(Route.preselectionsSuggested.slice(1))
    ) {
      setLastPage(Route.investorProfile);
    } else if (location.pathname.includes(Route.index)) {
      setLastPage(Route.index);
    }
  }, [location.pathname]);

  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const openContactDialog = useCallback(() => setContactDialogOpen(true), []);
  const closeContactDialog = useCallback(() => setContactDialogOpen(false), []);

  const [contactDialogFinishedOpen, setContDgFinOpen] = useState(false);
  const openContactDgFinished = useCallback(() => setContDgFinOpen(true), []);
  const closeContactDgFinished = useCallback(() => setContDgFinOpen(false), []);

  const [featureDialogOpen, setFeatureDialog] = useState<boolean>(false);
  const openFeatureDialog = useCallback(() => setFeatureDialog(true), []);
  const closeFeatureDialog = useCallback(() => setFeatureDialog(false), []);

  //This is congrats modal
  const [congratsModalInfo, setCongratsModalInfo] = useState(undefined);
  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  const openCongratsModal = useCallback((missionInfo) => {
    setCongratsModalInfo(missionInfo);
    setCongratsModalOpen(true);
  }, []);
  const closeCongratsModal = useCallback(() => setCongratsModalOpen(false), []);

  const [goalModalOpen, setGoalModalOpen] = useState(false);
  const openGoalModal = useCallback(() => setGoalModalOpen(true), []);
  const closeGoalModal = useCallback(() => setGoalModalOpen(false), []);

  //This is misions modal
  const [missionsModalInfo, setMissionsModalInfo] = useState(undefined);
  const [missionsModalOpen, setMissionsModalOpen] = useState(false);
  const openMissionsModal = useCallback((missionsInfo) => {
    setMissionsModalInfo(missionsInfo);
    setMissionsModalOpen(true);
  }, []);
  const closeMissionsModal = useCallback(() => setMissionsModalOpen(false), []);

  //This is NoGoalWarningModal Warning
  const [noGoalWarningModalOpen, setNoGoalWarningModalOpen] = useState(false);
  const openNoGoalWarningModal = useCallback(
    () => setNoGoalWarningModalOpen(true),
    []
  );
  const closeNoGoalWarningModal = useCallback(
    () => setNoGoalWarningModalOpen(false),
    []
  );

  //This is NoPersonalDataWarningModal
  const [noPersonalDataWarningModalOpen, setNoPersonalDataWarningModalOpen] =
    useState(false);
  const openNoPersonalDataWarningModal = useCallback(
    () => setNoPersonalDataWarningModalOpen(true),
    []
  );
  const closeNoPersonalDataWarningModal = useCallback(
    () => setNoPersonalDataWarningModalOpen(false),
    []
  );

  //This is AddCompanyModal
  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
  const openAddCompanyModal = useCallback(
    () => setAddCompanyModalOpen(true),
    []
  );
  const closeAddCompanyModal = useCallback(
    () => setAddCompanyModalOpen(false),
    []
  );

  const context = useMemo(
    () => ({
      fundsWithSeries,
      lastPage,
      contactDialogOpen,
      openContactDialog,
      closeContactDialog,
      contactDialogFinishedOpen,
      openContactDgFinished,
      closeContactDgFinished,
      openFeatureDialog,
      closeFeatureDialog,
      goalModalOpen,
      openGoalModal,
      closeGoalModal,
      setGoalModalOpen,
      congratsModalOpen,
      openCongratsModal,
      closeCongratsModal,
      missionsModalOpen,
      openMissionsModal,
      closeMissionsModal,
      noGoalWarningModalOpen,
      openNoGoalWarningModal,
      closeNoGoalWarningModal,
      noPersonalDataWarningModalOpen,
      openNoPersonalDataWarningModal,
      closeNoPersonalDataWarningModal,
      addCompanyModalOpen,
      openAddCompanyModal,
      closeAddCompanyModal,
    }),
    [
      fundsWithSeries,
      lastPage,
      contactDialogOpen,
      openContactDialog,
      closeContactDialog,
      contactDialogFinishedOpen,
      openContactDgFinished,
      closeContactDgFinished,
      openFeatureDialog,
      closeFeatureDialog,
      congratsModalOpen,
      openCongratsModal,
      goalModalOpen,
      openGoalModal,
      closeGoalModal,
      setGoalModalOpen,
      closeCongratsModal,
      missionsModalOpen,
      openMissionsModal,
      closeMissionsModal,
      noGoalWarningModalOpen,
      openNoGoalWarningModal,
      closeNoGoalWarningModal,
      noPersonalDataWarningModalOpen,
      openNoPersonalDataWarningModal,
      closeNoPersonalDataWarningModal,
      addCompanyModalOpen,
      openAddCompanyModal,
      closeAddCompanyModal,
    ]
  );

  const freshchat = useCallback(() => {
    if(useAuth().isLoggedIn && currentUser){
      return (
        <Freshchat 
          token={process.env.GATSBY_FRESHCHAT || ""} 
          externalId={currentUser.id.toString()} 
          firstName={currentUser.name}
          lastName={`${currentUser.personalData.paternalSurname} - ${currentUser.id}`}
          email={currentUser.email}
          phone={currentUser.personalData.phone}
        />
      )
    }
  }, [currentUser])

  return (
    <AppContext.Provider value={context}>
      <>
        {children}
        <ContactDialog open={contactDialogOpen} />
        <ContactDialogFinished open={contactDialogFinishedOpen} />
        <FeatureComingSoonDialog open={featureDialogOpen} />
        <GoalModal open={goalModalOpen} />
        <CongratsModal
          missionInfo={congratsModalInfo}
          open={congratsModalOpen}
        />
        <MissionsModal
          missionInfo={missionsModalInfo}
          open={missionsModalOpen}
        />
        <NoGoalWarningModal open={noGoalWarningModalOpen} />
        <NoPersonalDataWarningModal open={noPersonalDataWarningModalOpen} />
        <AddCompanyModal open={addCompanyModalOpen} />
        {freshchat()}
      </>
    </AppContext.Provider>
  );
};
