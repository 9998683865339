import { ApolloClient, gql, useQuery } from "@apollo/client";
import { UserFinancialData } from "@interfaces";

export const GET_USER_FINANCIAL_DATA = gql`
  query userIdeaFinancialData {
    id
    averageIncome
    variableExpenses
    fixedExpenses
    debts
    savings
    defaultDebts
  }
`;

interface QueryResult {
  userIdeaFinancialData: UserFinancialData;
}

interface HookReturn {
  userIdeaFinancialData: UserFinancialData;
  userIdeaFinancialDataLoading: boolean;
  refetchUserIdeaFinancialData: () => void;
}

export const getUserIdeaFinancialData = (): HookReturn => {
  const { data, loading, refetch } = useQuery<QueryResult>(
    GET_USER_FINANCIAL_DATA
  );
  return {
    userIdeaFinancialData: data?.userIdeaFinancialData ?? {},
    userIdeaFinancialDataLoading: loading,
    refetchUserIdeaFinancialData: refetch,
  };
};
