import { gql, useQuery } from "@apollo/client";
import { User } from "@interfaces";
import { useAuth } from "@hooks";

export const GET_CURRENT_USER_QUERY = gql`
  query GetMe {
    getMe {
      id
      name
      email
      roks
      level
      company {
        name
        owner
        rut
        canInvest
      }
      wantInfo
      haveVectorAccount
      isPersonalDataCompleted
      isSpouseDataCompleted
      isSpecialDataCompleted
      isBankDataCompleted
      isIdentityValidated
      areDocumentsSigned
      isOnboardingCompleted
      preferences
      investorProfile {
        savedCapital
        mainGoal
        investmentTerm
        risk
        investmentExperience
        investmentConcern
        currentInvesting
        investmentDropResponse
        investorProfile
        manuallySelectedInvestorProfile
      }
      personalData {
        birthdate
        gender
        profession
        paternalSurname
        maternalSurname
        rut
        nationality
        jobType
        workPosition
        employerName
        maritalState
        maritalPropertyRegime
        spouseName
        spousePaternalSurname
        spouseMaternalSurname
        spouseRut
        spouseBirthdate
        spouseNationality
        spouseAddress
        spouseGender
        address
        apartment
        phone
        fundsSource
        incomes
        monthlySaving
        isUSperson
        isPEP
        isQualifiedInvestor
        autoInvestCashSurplus
        agreement
        hasAGFrelation
        hasEnterpriseRelation
      }
      bankData {
        bank
        accountType
        accountNumber
      }
    }
  }
`;

interface QueryResult {
  getMe: User | null;
}

interface HookReturn {
  user: User | null;
  loading: boolean;
  refetchUser: () => void;
}

export const useCurrentUser = (): HookReturn => {
  const { isLoggedIn } = useAuth();
  const { data, loading, refetch } = useQuery<QueryResult>(
    GET_CURRENT_USER_QUERY,
    {
      skip: !isLoggedIn,
    }
  );
  return {
    user: isLoggedIn ? data?.getMe ?? null : null,
    loading,
    refetchUser: refetch,
  };
};

export const currentUserPreferences = () => {
  const { user, refetchUser } = useCurrentUser();
  return {
    onBoarding: user?.preferences?.onBoarding ?? [],
    budgetGoals: user?.preferences?.budgetGoals ?? [],
    refetchUser,
  };
};