import React from "react";
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
} from "@images";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { RokinWhiteComplete } from "@images";
import { OperationalInfo, RRSSdata } from "@data";

interface FooterProps {
  className?: string;
}

export const Footer: React.FC<FooterProps> = ({ className }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");

  return (
    <footer className={`${className} ${classes.root}`}>
      <nav className={classes.nav}>
        <div className={classes.navDiv}>
          <div className={classes.blockIcon}>
            <img
              src={RokinWhiteComplete}
              alt="rokin"
              className={classes.image}
            />
          </div>
          <div className={classes.redes}>Síguenos en</div>
          <ul className={classes.list}>
            <li className={`${isMobile && classes.listItem}`}>
              <a href={RRSSdata.facebookUrl} target="_blank" rel="noreferrer">
                <img
                  className={classes.imgList}
                  src={FacebookLogo}
                  alt="Facebook"
                />
              </a>
            </li>
            <li className={classes.listItem}>
              <a href={RRSSdata.instagramUrl} target="_blank" rel="noreferrer">
                <img
                  className={classes.imgList}
                  src={InstagramLogo}
                  alt="Instagram"
                />
              </a>
            </li>
            <li className={classes.listItem}>
              <a href={RRSSdata.linkedinUrl} target="_blank" rel="noreferrer">
                <img
                  className={classes.imgList}
                  src={LinkedinLogo}
                  alt="Linkedin"
                />
              </a>
            </li>
            <li className={classes.listItem}>
              <a href={RRSSdata.twitterUrl} target="_blank" rel="noreferrer">
                <img
                  className={classes.imgList}
                  src={TwitterLogo}
                  alt="Twitter"
                />
              </a>
            </li>
          </ul>
          {isMobile && (
            <div className={classes.footerRedes}>
              <ul className={classes.listUl}>
                <li className={classes.listUlLast}>
                  <div className={classes.titleList}>Empresas</div>
                  <a href="#Form" className={classes.ulLiItems}>
                    {OperationalInfo.joinRokin}
                  </a>
                </li>
              </ul>
            </div>
          )}{" "}
        </div>
        <div className={classes.containerFooter}>
          <div className={classes.footerGrid}>
            <ul className={classes.listUl}>
              <li className={classes.listUlLast}>
                <div className={classes.titleList}>¿Tienes dudas?</div>
                <div className={classes.ulLiItems}>
                  {OperationalInfo.mainEmail}
                </div>
              </li>
            </ul>
            <ul className={classes.listUl}>
              <li className={classes.listUlLast}>
                <div className={classes.titleList}>Contáctanos</div>
                <div className={classes.ulLiItems}>
                  {OperationalInfo.mainEmail}
                </div>
                <a
                  className={classes.ulLiItems}
                  href={`tel:${OperationalInfo.phone}`}
                >
                  {OperationalInfo.phone}
                </a>
                <div className={classes.ulLiItems}>
                  {OperationalInfo.direction}
                </div>
              </li>
            </ul>
          </div>
          <div className={classes.footerGrid}>
            <ul className={classes.listUl}>
              <li className={classes.listUlLast}>
                <div className={classes.titleList}>Conoce Rokin</div>
                <a className={classes.ulLiItems}>Programa incentivos</a>
                {/* <a className={classes.ulLiItems}>{OperationalInfo.saving}</a>
                <a className={classes.ulLiItems}>{OperationalInfo.rrhh}</a> */}
              </li>
            </ul>
            {/* <ul className={classes.listUl}>
              <li className={classes.listUlLast}>
                <div className={classes.titleList}>Nosotros</div>
                <a className={classes.ulLiItems}>{OperationalInfo.aboutUs}</a>
                <a className={classes.ulLiItems}>
                  {OperationalInfo.workWithUs}
                </a>
              </li>
            </ul> */}
          </div>
          <div className={classes.footerGrid}>
            <ul className={classes.listUl}>
              <li className={classes.listUlLast}>
                <div className={classes.titleList}>Personas</div>
                <a className={classes.ulLiItems}>{OperationalInfo.myRokin}</a>
                {/*    <a className={classes.ulLiItems}>
                  {OperationalInfo.firstSteps}
                </a> */}
              </li>
            </ul>
            {!isMobile && (
              <ul className={classes.listUl}>
                <li className={classes.listUlLast}>
                  <div className={classes.titleList}>Empresas</div>
                  <a href="#Form" className={classes.ulLiItems}>
                    {OperationalInfo.joinRokin}
                  </a>
                </li>
              </ul>
            )}{" "}
          </div>
        </div>
      </nav>
    </footer>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingLeft: "0rem",
    paddingRight: "0.5rem",
    padding: "4rem",
    paddingBottom: "19rem",
    position: "relative",
    zIndex: 994,
    [theme.breakpoints.down(1240)]: {
      paddingBottom: "9rem",
    },
  },
  navbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  image: {
    marginRight: 24,
    marginTop: 19,
    width: 200,
    paddingBottom: 13,
    [theme.breakpoints.down(1240)]: {
      width: 180,
    },
  },
  contact: {
    margin: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  li: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 600,
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "space-between",
    },
  },
  navDiv: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(1240)]: {
      paddingRight: 50,
    },
  },
  list: {
    paddingTop: 10,
    marginBottom: 4,
    paddingLeft: "1.5rem",
    flexDirection: "row",
    listStyle: "none",
    padding: 0,
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down(768)]: {
      paddingTop: 0,
      marginBottom: 24,
      justifyContent: "space-around",
      flexWrap: "wrap",
      width: 100,
      textAlign: "start",
      gap: "1rem",
    },
  },
  listItem: {
    marginLeft: "1rem",
    [theme.breakpoints.down(1240)]: {
      marginLeft: 0,
    },
  },
  imgList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  listUl: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(768)]: {
      flexDirection: "row",
    },
  },
  listUlLi: {
    color: "rgba(255, 255, 255)",
    fontSize: 14,
    lineHeight: "14px",
    marginBottom: 32,
    [theme.breakpoints.up(768)]: {
      margiBottom: 0,
      marginRight: 64,
    },
    [theme.breakpoints.up(1024)]: {
      marginRight: 128,
    },
  },
  titleList: {
    fontFamily: "Montserrat",
    color: "rgba(255, 255, 255)",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: "16px",
    marginBottom: 8,
  },
  ulLiItems: {
    marginBottom: 4,
    color: "rgba(255, 255, 255)",
    fontSize: "1rem",
    textDecoration: "none",
    display: "block",
    fontWeight: 100,
    fontFamily: "Montserrat, sans-serif",
  },
  listUlLiSecond: {
    color: "rgba(255, 255, 255)",
    fontSize: 14,
    lineHeight: "14px",
    marginBottom: 32,
    [theme.breakpoints.up(768)]: {
      margiBottom: 0,
      marginRight: 64,
    },
    [theme.breakpoints.up(1024)]: {
      marginRight: 128,
    },
  },
  listUlLast: {
    color: "rgba(255, 255, 255)",
    fontSize: 14,
  },
  logoText: {
    color: "white",
    fontSize: "3rem",
    fontWeight: 600,
    height: 69,
    marginRight: "6.25rem",
    [theme.breakpoints.down(1024)]: {
      fontSize: 25,
      height: 41,
      marginTop: "2rem",
    },
  },
  blockIcon: {
    display: "flex",
    alignItems: "end",
  },
  redes: {
    paddingTop: 20,
    fontSize: "1.5rem",
    fontWeight: 100,
    color: "white",
    paddingLeft: "1.5rem",
    [theme.breakpoints.down(1024)]: {
      fontSize: 14,
      paddingBottom: 10,
    },
  },
  footerGrid: {
    display: "grid",
    gap: "2rem",
  },
  containerFooter: {
    display: "flex",
    paddingTop: 36,
    "& >div": {
      marginRight: "13rem",
      marginTop: "2rem",
    },
    "& >div:first-child": {
      marginTop: 0,
    },
    [theme.breakpoints.down(1240)]: {
      display: "grid",
      "& >div": {
        marginRight: 20,
      },
    },
  },
  footerRedes: {
    [theme.breakpoints.down(1240)]: {
      "& ul:first-child": {
        marginTop: "3.2rem",
        paddingLeft: "1.5rem",
      },
    },
  },
}));
