import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Dialog } from '@material-ui/core';
import { NewInvestContext } from '@context';
import { ButtonPrimary, NumericInput } from '@components/Reusables';
import { makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from '@material-ui/core';
import { AddIcon } from '@images';
import { navigate } from 'gatsby';
import {
  CongratsModalType,
  Route,
  MovementType,
  InvestmentType,
} from '@interfaces';
import { Select, TextInput } from '@components';
import {
  useInvestmentsOptions,
  useInvestmentHelperText,
  useFundType,
} from '@hooks';
import { useGoals, usePortfolios, useUserInvestments } from '@apollo';
import { groupArrayBy } from '@utils';
import { DEPOSIT_ICON_PURPLE } from 'images/GCP';

interface AddFundsModalProps {
  open: boolean;
  onClose: () => void;
  selectedTarget: number;
}

export const AddFundsModal: React.FC<AddFundsModalProps> = ({
  open,
  onClose,
  selectedTarget,
}) => {
  const classes = useStyles();
  const { form, onChange, onContinueClick, validInvestedAmount } =
    useContext(NewInvestContext);
  const { portfolios, loading: loadingPortfolios } = usePortfolios();
  const { investments, loading: loadingInvestments } = useUserInvestments();
  const isMobile = useMediaQuery('(max-width: 1279px)');
  const [investmentsAndPortfolios, setInvestmentsAndPortfolios] =
    useState(undefined);
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);

  const { goals } = useGoals();
  //form for new investment
  const [selectDisable, setSelectDisable] = useState<boolean>(false);

  const [goalValue, setGoalValue] = useState<string>('null');
  const [fundValue, setFundValue] = useState<string>('null');
  const [targetOptions, setTargetOptions] = useState([
    { value: 'null', name: 'No hay objetivo' },
  ]);
  const [fundsOptions, setFundsOptions] = useState([
    { value: 'null', name: 'No hay Fondos' },
  ]);

  function getTargetOptions(targetData) {
    const targetArray = [];

    targetData.forEach((element) => {
      targetArray.push({ value: element.id, name: element.name });
    });

    setGoalValue(targetArray[0].value);

    return targetArray;
  }

  function getFundsOptions(data) {
    const fundsArray = [];
    if (data.length == 0) {
      form.suggestedPortfolio = true;
    } else {
      form.suggestedPortfolio = false;
    }
    if (form.suggestedPortfolio) {
      setFundValue('Conservador');
      fundsArray.push({ value: 'Conservador', name: 'Conservador' });
      fundsArray.push({ value: 'Moderado', name: 'Moderado' });
      fundsArray.push({ value: 'Agresivo', name: 'Agresivo' });
      fundsArray.push({ value: 'S&P 500', name: 'S&P 500' });
    } else {
      data.forEach((element) => {
        if (element.__typename == 'Portfolio') {
          fundsArray.push({ value: element.id, name: element.name });
        } else {
          fundsArray.push({ value: element.id, name: element.fund.name });
        }
        form.fund = element.id;
        setFundValue(element.id);
      });
    }

    return fundsArray;
  }

  useEffect(() => {
    if (goals.length !== 0) {
      setTargetOptions(getTargetOptions(goals));
    }
    if (selectedTarget) {
      setGoalValue(selectedTarget);
      setSelectDisable(true);
    } else {
      setSelectDisable(false);
    }
  }, [open, selectedTarget]);

  useEffect(() => {
    setInvestmentsAndPortfolios([...portfolios, ...investments]);
    setFundsOptions(
      getFundsOptions(
        [...portfolios, ...investments].filter(
          (obj) => obj.goalId === goalValue
        )
      )
    );
  }, [goalValue]);

  const { fundType } = useFundType(form.fund);

  const { notValidAmount, helperText } = useInvestmentHelperText(
    form.amount,
    form.serieMinimum,
    form.serieShareValue,
    form.suggestedPortfolio,
    fundType
  );

  useEffect(() => {
    if (form.amount == 0) {
      setInvalidAmount(true);
    } else {
      setInvalidAmount(false);
    }
  }, [form.amount]);

  const handleOnClick = () => {
    if (form.suggestedPortfolio) {
      form.goal = goalValue;
      form.investorProfile = fundValue;
      form.investmentAsset = 'SINGULAR';
      onContinueClick();
    } else {
      const investmentArray = investmentsAndPortfolios.filter(
        (obj) => obj.id === fundValue
      )[0];
      //If suggestedPortfolio is newInvestment in
      if (investmentArray.__typename == 'Portfolio') {
        form.goal = goalValue;
        form.portfolioId = investmentArray.id;
        form.portfolioName = investmentArray.name;
        onContinueClick();
      } else {
        form.agf = investmentArray.agf.id;
        form.goal = goalValue;
        form.serie = investmentArray.serie.id;
        onContinueClick();
      }
    }

    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} className={classes.Dialog}>
        <IconButton onClick={onClose} className={classes.CloseIcon}>
          <CloseIcon htmlColor='#0a5669' />
        </IconButton>
        <div className={classes.outerBorder}>
          <div className={classes.Modal}>
            <div className={classes.info}>
              <div className={classes.titulo}>
                <div className={classes.numberInfo}>
                  <img
                    src={DEPOSIT_ICON_PURPLE}
                    className={classes.numberImg}
                  />
                </div>
                <div className={classes.titleText}>Agregar Fondos</div>
              </div>
              <div className={classes.linea}></div>
              <div className={classes.savingOn}>
                <div className={classes.columner}>
                  <div className={classes.savingText}>Ahorraré en:</div>
                  <div className={classes.savingText2}>OBJETIVO</div>
                  <Select
                    className={classes.targetSelect}
                    name='sort'
                    value={goalValue}
                    onChange={(e) => setGoalValue(e.target.value)}
                    size={'large'}
                    //disabled={}
                    options={targetOptions}
                  ></Select>
                </div>
                <div className={classes.columner}>
                  <div className={classes.savingText3}>Se invertirá en:</div>

                  <Select
                    className={classes.targetSelect}
                    name='sort'
                    value={fundValue}
                    onChange={(e) => [
                      (form.fund = e.target.value),
                      setFundValue(e.target.value),
                    ]}
                    size={'large'}
                    options={fundsOptions}
                  ></Select>
                </div>
              </div>
              <div className={classes.amountOf}>
                <NumericInput
                  className={classes.amountText}
                  name='amount'
                  label='Monto a invertir'
                  value={form.amount}
                  onChange={onChange}
                  error={notValidAmount}
                  showHelperText
                  helperText={helperText}
                ></NumericInput>
                <ButtonPrimary
                  className={classes.button}
                  secondaryVersion
                  noDegraded
                  text='Listo'
                  disabled={invalidAmount}
                  onClick={handleOnClick}
                />
              </div>
              <div className={classes.text2}>
                Recuerda que invertirás a través de Vector Corredora de Bolsa
                S.A (i)
                <span>
                  Recuerda que rentabilidades pasadas no aseguran rentabilidades
                  futuras* "Infórmese de las características esenciales de la
                  inversión en este Fondo Mutuo, las que se encuentran
                  contenidas en su reglamento interno y contrato de suscripción
                  de cuotas. Además, recuerde que la rentabilidad o ganancia
                  obtenida en el pasado por este fondo no garantiza que ella se
                  repita en el futuro."
                </span>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    outerBorder: {
      width: '42.5rem',
      height: '38.5rem',
      background: 'rgba(0,0,0,0)',
      border: '1px solid white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 35,
      [theme.breakpoints.down(720)]: {
        width: 'auto',
        height: 'auto',
        padding: '0.25rem',
      },
    },
    Dialog: {
      '& >div >div': {
        borderRadius: '35px',
        background: 'rgba(0,0,0,0)',
        maxWidth: 900,
      },
    },
    Modal: {
      width: '42rem',
      height: '38rem',
      flexGrow: 0,
      padding: '22px 44px 22px 44px',
      borderRadius: 35,
      backgroundColor: 'rgba(237, 235, 235, 0.9)',
      [theme.breakpoints.down(720)]: {
        width: '310px',
        height: 'auto',
        minHeight: 500,
        padding: '0',
      },
    },
    info: {
      display: 'grid',
      borderRadius: 35,
      [theme.breakpoints.down(720)]: {
        backgroundColor: 'rgba(237, 235, 235, 0.9)',
      },
    },
    titulo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '3rem 0 1rem 1rem',
      [theme.breakpoints.down(720)]: {
        margin: '3rem 0 1rem 0rem',
        paddingLeft: '2rem',
      },
    },
    CloseIcon: {
      position: 'absolute',
      right: 20,
      top: 65,
      [theme.breakpoints.down(720)]: {
        top: 20,
      },
    },
    numberInfo: {
      position: 'relative',
      margin: '-20px -15px -10px -15px',
      '& >span': {
        position: 'absolute',
        zIndex: 3,
        top: 37,
        left: 51,
        color: '#4619b6',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'montserrat',
        [theme.breakpoints.down(720)]: {
          position: 'relative',
        },
      },
    },
    numberImg: {},
    roksImg: {
      width: 60,
      height: 'auto',
    },
    titleText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#393E44',
      marginLeft: '2rem',
      [theme.breakpoints.down(720)]: {
        fontSize: 18,
      },
    },
    text1: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#393E44',
      marginTop: '20px',
      [theme.breakpoints.down(720)]: {
        fontSize: 18,
      },
    },
    linea: {
      width: '100%',
      height: 2,
      flexGrow: 0,
      backgroundColor: '#fff',
      [theme.breakpoints.down(720)]: {
        width: '90%',
        marginLeft: '5%',
      },
    },
    text2: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 600,
      color: '#393E44',
      marginTop: 40,
      display: 'grid',
      [theme.breakpoints.down(720)]: {
        margin: '1rem 0 2rem 0',
        padding: '0 1rem 0 1rem',
      },
      '& >span': {
        marginTop: '10px',
        color: '#393E44',
        fontSize: '12px',
        fontWeight: 'normal',
        fontFamily: 'Montserrat',
        [theme.breakpoints.down(720)]: {
          marginTop: '0px',
        },
      },
    },
    textButton: {
      display: 'grid',
    },
    text3: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: '#393e44',
      marginBottom: 10,
    },
    textButton2: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    text4: {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 'normal',
      color: '#000000',
      margin: '20px 20px 0 0',
    },
    roks: {
      display: 'flex',
    },
    button: {
      width: 'auto',
      height: '3rem',
      right: 15,
      fontSize: '1rem',
      marginTop: '1rem',
      marginLeft: '-2rem',
      [theme.breakpoints.down(720)]: {
        width: '60%',
        marginTop: '1rem',
        marginLeft: '20%',
        right: 0,
      },
    },
    left: {
      left: '48px!important',
    },
    columner: {
      width: '50%',
      [theme.breakpoints.down(720)]: {
        width: '100%',
      },
    },
    savingOn: {
      display: 'flex',
      paddingTop: 70,
      paddingBottom: 0,
      gap: '15%',
      [theme.breakpoints.down(720)]: {
        display: 'grid',
        paddingTop: 10,
      },
    },
    targetSelect: {
      width: '100%',
      boxShadow: '4px 4px 8px -3px rgb(0 0 0 / 60%)',
      height: 44,
      top: 10,
      [theme.breakpoints.down(720)]: {
        width: '90%',
        margin: '0 5% 0 5%',
      },
    },
    targetText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      fontWeight: 'normal',
      color: '#008296',
      padding: '15px 0 0 5px',
      [theme.breakpoints.down(720)]: {
        marginTop: '2rem',
      },
    },
    amountOf: {
      display: 'flex',
      paddingTop: 40,
      gap: '45%',
      paddingBottom: 20,
      [theme.breakpoints.down(720)]: {
        display: 'grid',
        gap: '1rem',
        padding: 0,
      },
    },
    savingText: {
      position: 'absolute',
      top: 145,
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: 600,
      [theme.breakpoints.down(720)]: {
        position: 'relative',
        fontSize: '0.75rem',
        top: 0,
        paddingLeft: '1rem',
        '&>div': {
          width: '100%',
        },
      },
    },
    savingText2: {
      position: 'absolute',
      top: 145,
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      marginTop: '1.5rem',
      color: '#6b6b6b',
      [theme.breakpoints.down(720)]: {
        position: 'relative',
        paddingLeft: '1rem',
        fontSize: '0.75rem',
        marginTop: '1rem',
        top: 0,
        '&>div': {
          width: '100%',
        },
      },
    },
    savingText3: {
      position: 'absolute',
      top: 145,
      fontSize: '0.875rem',
      color: '#393e44',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      marginTop: '1.5rem',
      [theme.breakpoints.down(720)]: {
        position: 'relative',
        marginTop: '0rem',
        fontSize: '0.75rem',
        top: 0,
        paddingLeft: '1rem',
        '&>div': {
          width: '100%',
        },
      },
    },
    savingText4: {
      position: 'absolute',
      top: 177,
      fontSize: '1rem',
      color: '#393e44',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      marginTop: '1.5rem',
      [theme.breakpoints.down(720)]: {
        position: 'relative',
        top: 0,
        '&>div': {
          width: '100%',
        },
      },
    },
    amountText1: {
      position: 'absolute',
      top: 214,
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: 600,
      [theme.breakpoints.down(720)]: {
        width: 120,
        top: 260,
      },
    },
    amountText: {
      boxShadow: '4px 4px 8px -3px rgb(0 0 0 / 60%)',
      top: '12px',
      width: '100%',
      [theme.breakpoints.down(720)]: {
        margin: '3rem 5% 0 5%',
        width: '90%',
      },
    },
  }),
  { index: 1 }
);
