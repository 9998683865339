import { ApolloClient } from "@apollo/client";
import { gql } from "@apollo/client";

const EDIT_FINERIO_TRANSACTION = gql`
  mutation Mutation($params: EditTransactionParams) {
    editFinerioTransaction(params: $params)
  }
`;

type EditFinerioTransactionParams = {
  transactionId: string;
  description: string;
  amount: number;
  date: string;
  subcategory: string;
};

export const editFinerioTransaction = async (
  client: ApolloClient<any>,
  params: EditFinerioTransactionParams
) => {
  const { data } = await client.mutate({
    mutation: EDIT_FINERIO_TRANSACTION,
    variables: { params: params },
  });

  return data;
};
