export function financialAnalysis(
  income: number,
  fixedExpense: number,
  varExpense: number,
  savingsAndInvestments: number,
  loansAndDebt: number,
  yearsOld: number,
  financialKnowledge: string
) {
  const anualInterestRate = 0.06;
  const savingsPercentageAfp = 0.1;

  // financialKnowledge = low || average || high
  const anualMandatorySavings =
    (savingsPercentageAfp *
      income *
      12 *
      (Math.pow(1 + anualInterestRate, 65 - yearsOld) - 1)) /
    anualInterestRate;

  const debtIncomeRatioPercentage = loansAndDebt / income;
  const lifeCapacityPercentage =
    savingsAndInvestments / (fixedExpense + varExpense);
  const potentialSavingsPercentage =
    (income - (fixedExpense + varExpense)) / income;
  const monthlyExpensePercentage = (fixedExpense + varExpense) / income;
  const replacementRatePercentage = anualMandatorySavings / 240 / income;

  const potentialSavingsWeight = 0.25;
  const lifeCapacityWeight = 0.15;
  const debtIncomeWeight = 0.25;
  const monthlyExpenseWeight = 0.15;
  const replacementRateWeight = 0.1;
  const financialKnowledgeWeight = 0.1;

  const getPoints = (
    percentage: number,
    val1: number,
    val2: number,
    val3: number,
    val4: number,
    res1: number,
    res2: number,
    res3: number
  ) => {
    if (percentage <= val1 && percentage >= val2) {
      return res1;
    } else if (percentage < val3) {
      return res2;
    } else if (percentage > val4) {
      return res3;
    } else {
      return 0;
    }
  };

  const getKnowledgePoints = (finKnow: string) => {
    if (finKnow === "average") {
      return 20;
    } else if (finKnow === "high") {
      return 30;
    } else if (finKnow === "low") {
      return 10;
    } else {
      return 0;
    }
  };

  const potentialSavnigsPoints = getPoints(
    potentialSavingsPercentage,
    0.3,
    0.101,
    0.1,
    0.301,
    20,
    10,
    30
  );
  const lifeCapacityPoints = getPoints(
    lifeCapacityPercentage,
    6,
    3,
    3,
    6,
    20,
    10,
    30
  );
  const debtIncomeRatioPoints = getPoints(
    debtIncomeRatioPercentage,
    0.5,
    0.3,
    0.3,
    0.5,
    20,
    30,
    10
  );
  const monthlyExpensePoints = getPoints(
    monthlyExpensePercentage,
    0.75,
    0.5,
    0.5,
    0.75,
    20,
    30,
    10
  );
  const replacementRatePoints = getPoints(
    replacementRatePercentage,
    0.7,
    0.8,
    0.7,
    0.8,
    20,
    10,
    30
  );
  const financialKnowledgePoints = getKnowledgePoints(financialKnowledge);

  let totalScore: number;

  if (debtIncomeRatioPercentage > 2) {
    totalScore = 0;
  } else {
    totalScore =
      potentialSavnigsPoints * potentialSavingsWeight +
      monthlyExpensePoints * monthlyExpenseWeight +
      lifeCapacityPoints * lifeCapacityWeight +
      replacementRatePoints * replacementRateWeight +
      debtIncomeRatioPoints * debtIncomeWeight +
      financialKnowledgePoints * financialKnowledgeWeight;
  }

  const status = () => {
    if (totalScore < 10) return "Crítico";
    if (totalScore <= 17 && totalScore >= 10) return "En riesgo";
    if (totalScore <= 24 && totalScore >= 18) return "Con potencial";
    if (totalScore <= 30 && totalScore >= 25) return "Saludable";
  };

  const description = () => {
    switch (status()) {
      case "Crítico":
        return "Te encuentras en una situación en la que debes actuar lo antes posible para no empeorar las consecuencias.";
      case "En riesgo":
        return "Tus deudas ocupan una parte importante de tu presupuesto y un pequeño desorden puede perjudicarte";
      case "Con potencial":
        return "Tienes tus cuentas ordenadas, pero puedes potenciar más tu situación financiera";
      case "Saludable":
        return "Tus cuentas están ordenadas, tus créditos y deudas son saludables y tienes una capacidad de ahorro bien aprovechada";
      default:
        return "Te encuentras en una situación en la que debes actuar lo antes posible para no empeorar las consecuencias.";
    }
  };

  const result = {
    score: totalScore ?? 0,
    status: status() ?? "Crítico",
    description: description(),
  };

  return result;
}
