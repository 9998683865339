import React, { useState, useCallback, useEffect, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import {
  ButtonOutlined,
  ButtonPrimary,
  GoalSelector,
  InvestPeriodRangeSelector,
  NumericInput,
  TextInput,
  FoundSelector,
} from '@components';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { Return } from 'images/Goal';
import { GoalsContext } from '@context';
import { Footer } from '../Landing/Footer';

interface LayoutProps {
  className?: string;
  styleFooter?: string;
  icon?: string;
  children: React.ReactNode;
}

export const LayoutDesktop: React.FC<LayoutProps> = ({
  className,
  styleFooter,
  icon,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={`${className || ''} ${classes.container}`}>
      <div className={classes.subContainer}>
        <section>
          <div className={classes.headerLayout}>
            <div className={classes.IconMision}>
              <img src={icon} />
            </div>
            <div
              onClick={() => navigate(Route.investments + '/?#objectives')}
              className={classes.return}
            >
              <img src={Return} />
              Volver
            </div>
          </div>
        </section>
        <section className={classes.containerData}>{children}</section>
      </div>
      <Footer className={`${classes.footer} ${styleFooter}`} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: 'none',
    },
  },
  subContainer: {
    display: 'flex',
    marginBottom: '10rem',
  },
  container: {
    overflowX: 'hidden',
    background: '#F4F3F3',
  },
  children: {},
  headerLayout: {
    display: 'grid',
    gap: '2rem',
    alignItems: 'center',
    paddingLeft: 106,
    paddingTop: 50,
    width: 200,
    justifyContent: 'center',
    '& img': {
      width: 140,
      height: 50,
    },
  },
  IconMision: {
    '& img': {
      width: 70,
      height: 70,
    },
  },
  containerData: {
    display: 'flex',
    gap: '2rem',
    marginTop: 60,
    marginLeft: '3rem',
    width: '100%',
    '& > div': {
      cursor: 'pointer',
      width: '100%',
    },
  },
  return: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '& img': {
      width: 50,
      height: 50,
    },
  },
}));
