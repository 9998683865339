import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import { navigate } from '@reach/router';
import { Route } from '@interfaces';
import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/Home';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonIcon from '@material-ui/icons/Person';
import { SERVICES_GREEN, SERVICES_GREY } from 'images/GoogleCloud';
import {
  INVESTMENTS_GREEN,
  INVESTMENTS_GREY,
  MY_FINANCE_GREEN,
  MY_FINANCE_GREY,
  ROKIPEDIA_GREEN,
  ROKIPEDIA_GREY,
  ROCKET_ICON,
  TREND_UP_ICON,
  SERVICES_ICON,
  ROKIN_LOGO,
  HOME_ICON,
} from '@images';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  activeInvest?: boolean;
  activeRokipedia?: boolean;
  activeFinance?: boolean;
  activeServices?: boolean;
}
export const LayoutMobileInvestments: React.FC<LayoutProps> = ({
  className,
  children,
  activeInvest,
  activeRokipedia,
  activeFinance,
  activeServices,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className || ''} ${classes.container}`}>
      <header className={classes.header}>
        <div
          className={classes.buttonWidth}
          onClick={() => navigate(Route.investments)}
        >
          {!activeInvest ? (
            <img className={classes.greyFilter} src={TREND_UP_ICON} />
          ) : (
            <img className={classes.greenFilter} src={TREND_UP_ICON} />
          )}
          <div className={`subtitle ${activeInvest ? classes.active : ''}`}>
            Mis Inversiones
          </div>
        </div>
        <div
          className={classes.buttonWidth}
          onClick={() => navigate(Route.finance)}
        >
          {!activeFinance ? (
            <img className={classes.greyFilter} src={HOME_ICON} />
          ) : (
            <img className={classes.greenFilter} src={HOME_ICON} />
          )}
          <div className={`subtitle ${activeFinance ? classes.active : ''}`}>
            Mis Finanzas
          </div>
        </div>
        <div
          className={classes.buttonWidth}
          onClick={() => navigate(Route.rokipedia)}
        >
          {!activeRokipedia ? (
            <img className={classes.greyFilter} src={ROCKET_ICON} />
          ) : (
            <img className={classes.greenFilter} src={ROCKET_ICON} />
          )}
          <div className={`subtitle ${activeRokipedia ? classes.active : ''}`}>
            Rokipedia
          </div>
        </div>
        <div
          className={classes.buttonWidth}
          onClick={() => navigate(Route.services)}
        >
          {!activeServices ? (
            <img className={classes.greyFilter} src={SERVICES_ICON} />
          ) : (
            <img className={classes.greenFilter} src={SERVICES_ICON} />
          )}
          <div className={`subtitle ${activeServices ? classes.active : ''}`}>
            Servicios
          </div>
        </div>
      </header>
      <div className={classes.children}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: 'none',
    },
  },
  container: {
    overflowX: 'hidden',
    background: '#edebeb',
  },
  children: {
    marginTop: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    background: '#F4F3F3',
    position: 'fixed',
    bottom: 0,
    zIndex: 995,
    justifyContent: 'space-around',
    width: '100%',
    height: '4.375rem',
    padding: '0.5rem',
    borderRadius: ' 10px 10px 0 0',
    boxShadow: '0 6px 15px 6px rgba(0, 0, 0, 0.15)',
    '& img': {
      width: '1.57rem',
      height: '1.57rem',
      filter: 'opacity(0.4) drop-shadow(red red red red)',
    },
    '& .subtitle': {
      color: theme.palette.text.secondary,
      fontSize: '0.625rem',
    },
    '& svg': {
      fill: '#d6d6d6',
      borderRadius: 15,
      [theme.breakpoints.down(1200)]: {
        width: '2.180rem',
        height: '2.180rem',
      },
    },
  },
  search: {
    color: 'rgb(157, 157, 157)',
    marginTop: 3,
  },
  active: {
    color: `${theme.palette.primary.main}!important`,
  },
  buttonWidth: {
    cursor: 'pointer',
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.3rem',
  },
  greyFilter: {
    filter:
      'invert(39%) sepia(1%) saturate(0%) hue-rotate(31deg) brightness(97%) contrast(91%)',
  },
  greenFilter: {
    filter:
      'invert(26%) sepia(93%) saturate(1930%) hue-rotate(167deg) brightness(93%) contrast(103%)',
  },
}));
