import {
  BUDGET_ICON,
  BUDGET_ICON_COLOR,
  SEARCH_ICON,
  SEARCH_ICON_COLOR,
  RETIREMENT_ICON,
  UMBRELLA_ICON_COLOR,
  DEBT_ICON,
  PAYMENT_ICON_COLOR,
  INVEST_ICON,
  PROFITS_ICON_COLOR,
  SAVING_ICON,
  SAFEBOX_ICON_COLOR,
  BILL_ICON_COLOR,
  BILL_ICON_PRODUCTTOUR,
  GOAL_ICON,
  TREND_UP_ICON_BLUE,
  SEARCHMONEY_ICON_BLUE,
  SAFEBOX_ICON_BLUE,
  PIECHART_ICON_BLUE,
  INVOICEDOLLAR_ICON_BLUE,
  GOAL_ICON_BLUE,
  CREDITCARDS_ICON_BLUE,
  BILL_ICON_BLUE,
  TREND_UP_ICON_GREY,
  SEARCHMONEY_ICON_GREY,
  SAFEBOX_ICON_GREY,
  PIECHART_ICON_GREY,
  INVOICEDOLLAR_ICON_GREY,
  GOAL_ICON_GREY,
  CREDITCARDS_ICON_GREY,
  BILL_ICON_GREY,
} from "images/AWS/productTour";

interface isSelectType {
  card: boolean;
  card1: boolean;
  card2: boolean;
  card3: boolean;
  card4: boolean;
  card5: boolean;
  card6: boolean;
  card7: boolean;
}

export const preferencesList = (isSelect: isSelectType) => [
  {
    id: 0,
    title: "Salir de DICOM",
    img: BILL_ICON_GREY,
    imgSelect: BILL_ICON_BLUE,
    select: isSelect ? isSelect.card : false,
  },
  {
    id: 1,
    title: "Aprender sobre productos financieros",
    img: SEARCHMONEY_ICON_GREY,
    imgSelect: SEARCHMONEY_ICON_BLUE,
    select: isSelect ? isSelect.card1 : false,
  },
  {
    id: 2,
    title: "Mejorar mis hábitos financieros",
    img: PIECHART_ICON_GREY,
    imgSelect: PIECHART_ICON_BLUE,
    select: isSelect ? isSelect.card2 : false,
  },
  {
    id: 3,
    title: "Manejar mis deudas",
    img: CREDITCARDS_ICON_GREY,
    imgSelect: CREDITCARDS_ICON_BLUE,
    select: isSelect ? isSelect.card3 : false,
  },
  {
    id: 4,
    title: "Invertir más seguido",
    img: TREND_UP_ICON_GREY,
    imgSelect: TREND_UP_ICON_BLUE,
    select: isSelect ? isSelect.card4 : false,
  },
  {
    id: 5,
    title: "Aumentar mi ahorro",
    img: SAFEBOX_ICON_GREY,
    imgSelect: SAFEBOX_ICON_BLUE,
    select: isSelect ? isSelect.card5 : false,
  },
  {
    id: 6,
    title: "Controlar mis gastos",
    img: INVOICEDOLLAR_ICON_GREY,
    imgSelect: INVOICEDOLLAR_ICON_BLUE,
    select: isSelect ? isSelect.card6 : false,
  },
  {
    id: 7,
    title: "Cumplir un objetivo",
    img: GOAL_ICON_GREY,
    imgSelect: GOAL_ICON_BLUE,
    select: isSelect ? isSelect.card7 : false,
  },
];
