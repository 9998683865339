export * from "./addMoment";
export * from "./portal";
export * from "./screenCategory";
export * from "./habitsDetails";

const SALDO_ICON =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/portal/saldoActual_icon+(1).svg";
const INFO_ICON =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/portal/Action+_+info+outline.svg";
const META_ICON =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/portal/metaGasto_icon.svg";
export { SALDO_ICON, INFO_ICON, META_ICON };
