import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { cardOn } from '@images';
import Addicon from '@material-ui/icons/Add';
import { ProfileContext, NewInvestContext, AppContext } from '@context';
import { I } from 'images/Investments';
import { parseNumber } from '@utils';
import { MovementType, Route } from '@interfaces';
import { navigate } from 'gatsby';
import { useGoals, useCurrentUser } from '@apollo';
import {
  DEPOSIT_ICON_WHITE,
  ADD_GOAL_WHITE,
  MONITOREAR_ICON_GREEN,
} from 'images/AWS/Investments/index';

const Saving: React.FC = ({ globals = null }) => {
  const classes = useStyles();
  const { openNoGoalWarningModal, openNoPersonalDataWarningModal } =
    useContext(AppContext);
  const { profileForm } = useContext(ProfileContext);
  const { openAddFundsModal, startFlow } = useContext(NewInvestContext);
  const { goals } = useGoals();
  const { user: currentUser } = useCurrentUser();

  const handleAddFundsClick = () => {
    if (goals.length == 0) {
      openNoGoalWarningModal();
    } else {
      if (
        !currentUser?.isIdentityValidated ||
        !currentUser?.isPersonalDataCompleted ||
        !currentUser?.isSpecialDataCompleted
      ) {
        openNoPersonalDataWarningModal();
      } else {
        openAddFundsModal(null);
        startFlow({
          isReinvest: true,
          movementType: MovementType.DEPOSIT,
        });
      }
    }
  };

  return (
    <section className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.titleCardContainer}>
          <div>
            <span className={classes.txtPrincipal}>Ahorro</span>
            <span className={classes.txtSecondary}>
              Revisa el estado de tus ahorros en Rokin, {profileForm.name}
            </span>
          </div>

          <div className={classes.button} onClick={handleAddFundsClick}>
            <img src={DEPOSIT_ICON_WHITE} alt="" />
            <span>Depositar</span>
            <span></span>
          </div>
          {/*<div className={classes.button2} onClick={handleAddFundsClick}>
            <img src={MONITOREAR_ICON_GREEN} alt="" />
            <span>Monitorear inversión</span>
            <span></span>
          </div>
          */}
        </div>

        <div className={classes.valuesContainer}>
          <div className={classes.imageCard}>
            <img src={cardOn} />
            <span className={classes.imageCardTitle}>Saldo Total</span>
            <span className={classes.imageCardValue}>
              <span>$</span>
              {parseNumber(globals.currentValue)}
            </span>
            <span className={classes.imageCardName}>
              {profileForm.name} {profileForm.paternalSurname}
            </span>
          </div>
          <div className={classes.containerCard}>
            <div className={classes.card}>
              <div className={classes.titleCard}>Aporte Personal</div>
              <div className={classes.valueCard}>
                <span>$</span>
                {parseNumber(globals.totalDeposited)}
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.titleCard}>Caja</div>
              <div className={classes.valueCard}>
                <span>$</span>
                {parseNumber(globals.cashValue)}
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.titleCard}>Ganancia total</div>
              <div className={classes.valueCard}>
                <span>$</span>
                {parseNumber(globals.profits)}
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.titleCard}>Retirado total</div>
              <div className={classes.valueCard}>
                <span>$</span>
                {parseNumber(globals.totalWithdrawn)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Saving;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },
  cardContainer: {
    background: '#F4F3F3',
    width: '95%',
    height: 350,
    boxShadow: '1.25rem 0.75rem 3.125rem 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 30,
  },
  titleCardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '1.875rem 0 0 0.8rem',
    width: '98%',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  txtPrincipal: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '2.25rem',
    fontWeight: 'bold',
    borderRight: '0.0625rem solid #6b6b6b',
    padding: 10,
    color: theme.palette.text.primary,
  },
  txtSecondary: {
    color: theme.palette.text.secondary,
    fontSize: '1.25rem',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    padding: 10,
    display: 'block',
    width: 600,
  },
  valuesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    paddingLeft: '1rem',
    justifyContent: 'space-between',
    width: '90%',
    padding: 0,
  },
  imageCard: {
    position: 'relative',
    paddingRight: '1rem',
    '& span': {
      color: 'white',
    },
    '& img': {
      width: 460,
      maxWidth: 460,
    },
  },
  imageCardTitle: {
    position: 'absolute',
    top: 80,
    left: 103,
    fontSize: 40.4,
    fontFamily: theme.typography.fontFamily,
    color: '#edebeb',
  },
  imageCardValue: {
    position: 'absolute',
    top: 130,
    left: 80,
    fontSize: 60,
    fontFamily: theme.typography.fontFamily,
    color: '#edebeb',
    '& span': {
      fontSize: '1rem',
    },
  },
  imageCardName: {
    position: 'absolute',
    top: 235,
    left: 80,
    fontWeight: 200,
    fontSize: 25.2,
    fontFamily: theme.typography.fontFamily,
    color: '#edebeb',
  },
  card: {
    display: 'grid',
    border: `0.0625rem solid ${theme.palette.primary.main}`,
    boxShadow: '0.175rem 0.175rem 0.175rem 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '1rem',
    padding: '0.5rem',
    height: 87,
    width: 175,
    textAlign: 'center',
    marginTop: '2.8rem',
  },
  titleCard: {
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    color: theme.palette.text.primary,
    fontWeight: 200,
  },
  valueCard: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: theme.palette.text.primary,
    '& span': {
      fontSize: '0.8rem',
      paddingRight: 2,
    },
  },
  button: {
    borderRadius: '10px',
    background: '#084554',
    boxShadow: '1.5px 1.5px 5.2px 0 rgba(0, 0, 0, 0.3)',
    padding: '0.2rem 0.4rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '18.5rem',
    height: '2.5rem',
    color: 'white',
    cursor: 'pointer',
    marginRight: '1rem',
    '& img': {
      marginLeft: '1rem',
    },
    '& span': {
      display: 'block',
      paddingLeft: 5,
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.9rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      marginRight: '0.5rem',
    },
  },
  button2: {
    borderRadius: '10px',
    border: 'solid 1px #008296',
    boxShadow: '1.5px 1.5px 5.2px 0 rgba(0, 0, 0, 0.3)',
    padding: '0.2rem 0.4rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '18.5rem',
    height: '2.5rem',
    color: '#0a5669',
    cursor: 'pointer',
    '& img': {
      marginLeft: '1rem',
    },
    '& span': {
      display: 'block',
      paddingLeft: 5,
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.9rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      marginRight: '0.5rem',
    },
  },
  containerCard: {
    display: 'flex',
    gap: '5%',
    paddingBottom: '3rem',
    position: 'relative',
    [theme.breakpoints.down(1400)]: {
      gap: '1.5%',
    },
  },
  viewCaja: {
    position: 'absolute',
    color: theme.palette.text.disabled,
    display: 'flex',
    bottom: 10,
    left: 20,
    fontWeight: 300,
    gap: 5,
  },
  addIcon: {
    width: 28,
    height: 28,
  },
}));
