const CALENDAR_ICON_GREEN =
  "https://storage.googleapis.com/rokinapp-images/finances/Info%20PopUp/calendar_icon.svg";
const FINANCES_ICON_GREEN =
  "https://storage.googleapis.com/rokinapp-images/finances/Info%20PopUp/finanzas_icon_verde.svg";
const LINK_ICON_GREEN =
  "https://storage.googleapis.com/rokinapp-images/finances/Info%20PopUp/link_icon.svg";
const MONITOR_ICON_GREEN =
  "https://storage.googleapis.com/rokinapp-images/finances/Info%20PopUp/monitoreo_icon.svg";
const ALFRED_MOBILE =
  "https://storage.googleapis.com/rokinapp-images/finances/alfred_mobile.svg";
const ALFRED_DESKTOP =
  "https://storage.googleapis.com/rokinapp-images/finances/Alfred_desktop.svg";
const ALFRED_MOBILE_NO_CLOSE =
  "https://storage.googleapis.com/rokinapp-images/finances/alfred02_mobile.svg";
const ALFRED_DESKTOP_NO_CLOSE =
  "https://storage.googleapis.com/rokinapp-images/finances/Alfred2_desktop.svg";
const ALFRED_SECOND_TEXT = "https://storage.googleapis.com/rokinapp-images/myHabits/portal/alfred20.png";

export {
  CALENDAR_ICON_GREEN,
  FINANCES_ICON_GREEN,
  LINK_ICON_GREEN,
  MONITOR_ICON_GREEN,
  ALFRED_MOBILE,
  ALFRED_DESKTOP,
  ALFRED_MOBILE_NO_CLOSE,
  ALFRED_DESKTOP_NO_CLOSE,
  ALFRED_SECOND_TEXT
};
