// ADD MOMENT
const FEEDING =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/alimentacion_icon_wbackground.svg";
const CALENDAR_ICON =
  "https://storage.googleapis.com/rokinapp-images/myHabits/addMovement/calendar_icon.svg";
const SHOPPING =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/compras_icon_wbackground.svg";
const SPORTS_AND_HOBBIES =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/deportesYHobbies_icon_wbackground.svg";
const EDUCATION_WORK =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/educacion_icon_wbackground.svg";
const ENTRETENIMIENT =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/entretenimiento_icon_wbackground.svg";
const FINANCE =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/finanzasEImpuestos_icon_wbackground.svg";
const PETS =
  "https://storage.googleapis.com/rokinapp-images/myHabits/addMovement/mascotas.svg";
const OTHERS =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/otros_icon_wbackground.svg";
const GIFTS =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/regalos_icon_wbackground.svg";
const RESTAURANTS =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/restaurantes_icon_wbackground.svg";
const HEALTH =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/saludYBelleza_icon_wbackground.svg";
const TRANSPORTATION =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/transporte_icon_wbackground.svg";
const TRIPS =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/viajes_icon_wbackground.svg";
const LIVING_PLACE =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/vivienda_icon_wbackground.svg";
const TRANSFERS =
  "https://storage.googleapis.com/rokinapp-images/myHabits/categories/transferencias_icon_wbackground.svg";
const HONORARIES_2D =
  "https://storage.googleapis.com/rokinapp-images/mission3/honorariosfinal.jpg";
const CREDITS_AND_QUOTAS_2D =
  "https://storage.googleapis.com/rokinapp-images/mission3/creditosYCuotasfinal.jpg";
const INSURANCE_2D =
  "https://storage.googleapis.com/rokinapp-images/mission3/securityfinal.jpg";
const FIXED_EXPENSES_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/GastoFijo.png";
const HOLIDAY_BONUS_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/aguinaldo.png";
const NUTRITION_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/alimentacion.png";
const BONUS_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/bonos.png";
const COMISSIONS_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/comisiones.png";
const CREDITS_AND_QUOTAS_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/creditosYCuotas.png";
const ACCOUNTS_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/cuentas.png";
const EDUCATION_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/educacion.png";
const ENTERTAINMENT_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/entretencion.png";
const AVERAGE_EXPENSES_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/gastoPromedio.png";
const VARIABLE_EXPENSES_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/gastoVariable.png";
const HONORARIES_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/honorarios.png";
const FIXED_INCOME_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/ingresoFijo.png";
const AVERAGE_INCOME_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/ingresoPromedio.png";
const VARIABLE_INCOME_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/ingresoVariable.png";
const NEW_CATEGORY_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/nuevaCategoria.png";
const NEW_EXPENSE_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/nuevoGasto.png";
const OTHERS_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/otros.png";
const SMALL_PURCHASES_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/peque%C3%B1asCompras.png";
const BUDGET_ILUSTRATION_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/presupuesto_ilustracion.png";
const RECOMENDATIONS_ILUSTRATION_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/recomendaciones_icon.svg";
const HEALTH_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/salud.png";
const INSURANCE_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/securityfinal.jpg";
const SALARY_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/sueldo.png";
const TRANSPORTATION_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/transporte.png";
const LIVING_PLACE_3D =
  "https://storage.googleapis.com/rokinapp-images/mission3/vivienda.png";
export {
  LIVING_PLACE,
  TRIPS,
  TRANSPORTATION,
  HEALTH,
  RESTAURANTS,
  GIFTS,
  OTHERS,
  PETS,
  FINANCE,
  ENTRETENIMIENT,
  EDUCATION_WORK,
  SPORTS_AND_HOBBIES,
  SHOPPING,
  CALENDAR_ICON,
  FEEDING,
  TRANSFERS,
  FIXED_EXPENSES_3D,
  HOLIDAY_BONUS_3D,
  NUTRITION_3D,
  BONUS_3D,
  COMISSIONS_3D,
  CREDITS_AND_QUOTAS_3D,
  ACCOUNTS_3D,
  EDUCATION_3D,
  ENTERTAINMENT_3D,
  AVERAGE_EXPENSES_3D,
  VARIABLE_EXPENSES_3D,
  HONORARIES_3D,
  FIXED_INCOME_3D,
  AVERAGE_INCOME_3D,
  VARIABLE_INCOME_3D,
  NEW_CATEGORY_3D,
  NEW_EXPENSE_3D,
  OTHERS_3D,
  SMALL_PURCHASES_3D,
  BUDGET_ILUSTRATION_3D,
  RECOMENDATIONS_ILUSTRATION_3D,
  HEALTH_3D,
  INSURANCE_3D,
  SALARY_3D,
  TRANSPORTATION_3D,
  LIVING_PLACE_3D,
  HONORARIES_2D,
  CREDITS_AND_QUOTAS_2D,
  INSURANCE_2D,
};
