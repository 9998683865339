import { FinerioAccount } from "@interfaces";
import { useMemo } from "react";
import { FinerioGlobals } from "@interfaces";
import { cloneDeep } from "lodash";

interface HookParams {
  finerioAccounts: FinerioAccount[];
  bankNotSelected?: number[];
}

type HookResults = FinerioGlobals;

export const useFinerioAccountsTotals = ({
  finerioAccounts,
  bankNotSelected,
}: HookParams): HookResults => {
  const globals = useMemo(() => {
    if (finerioAccounts.length !== 0) {
      const finerioAccountsClone = cloneDeep(finerioAccounts); //Cloning accounts array to be able to modify it
      finerioAccountsClone.sort((a, b) => b.accountBalance - a.accountBalance); //Sorting from highest to lowest

      let total = 0;
      let totalUsed = 0;
      let totalCreditLimit = 0;

      //Deleting account with enabled = false
      const filteredAccounts = finerioAccountsClone.filter(
        (account) => account.enabled === true
      );

      //Deleting aditional account
      const uniqueAccounts = filteredAccounts.reduce((acc, current) => {
        const x = acc.find((item) => {
          if (
            item.accountType == "Crédito" &&
            current.accountType == "Crédito"
          ) {
            return item.accountName === current.accountName;
          } else {
            return;
          }
        });
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      uniqueAccounts.forEach((account) => {
        if (!bankNotSelected?.includes(Number(account.bankId))) {
          if (account.accountType !== "") {
            if (account.accountType === "Crédito") {
              totalUsed += account.accountBalance;
            } else {
              total += account.accountBalance;
            }
            const CreditJson = { creditLimit: 0 };
            totalCreditLimit += CreditJson.creditLimit;
          }
        }
      });

      const totalBalance = total;
      const totalUsedBalance = totalUsed;
      const CreditLimit = totalCreditLimit;
      const amountAfterPayingCards = totalBalance - totalUsedBalance;

      return {
        totalBalance,
        totalUsedBalance,
        CreditLimit,
        amountAfterPayingCards,
      };
    } else {
      const totalBalance = 0;
      const totalUsedBalance = 0;
      const CreditLimit = 0;
      const amountAfterPayingCards = totalBalance - totalUsedBalance;

      return {
        totalBalance,
        totalUsedBalance,
        CreditLimit,
        amountAfterPayingCards,
      };
    }
  }, [finerioAccounts, bankNotSelected]);
  return globals;
};
