import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./calendarComponent.styles";

import MobileCalendar from "@components/Finance/Calendar/MainCalendar";
import {
  CalendarInfoContainer,
  CalendarFilter,
  MainEventsDetail,
  CreateEvent,
} from "@components";
import {
  RECURRENT_EXPENSES_ICON_WB,
  RECURRENT_INCOME_ICON_WB,
  REMINDER_ICON_WB,
} from "@images";
import { getAllEvents } from "@apollo";
import { getDayEvents } from "apollo/calendar/getDayEvents.query";
import { createEventMutation } from "apollo/calendar/createEvent.mutation";
import { ContactSupportOutlined } from "@material-ui/icons";

const Calendar = (props: {
  fullScreen: boolean;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  togglePage: () => void;
}) => {
  // sacado de https://derrickotte.medium.com/how-to-create-a-calendar-from-scratch-in-react-1f2db197454d
  const classes = useStyles();
  const isMobile = useMediaQuery("(min-width:1239px)");

  const [currentDay, setCurrentDay] = useState(new Date());
  // get today date
  const [weekly, setWeekly] = useState(false);
  const [skip, setSkip] = useState(true);
  const { allEvents, refetchAllEvents } = getAllEvents();
  const [eventsData, setEventsData] = useState([]);

  const [currentView, setCurrentView] = useState("calendar");
  props.setFullScreen(currentView !== "calendar");

  useEffect(() => {
    const fetchData = async () => {
      const response = await allEvents();
      if (response) {
        setEventsData(response?.data?.allUserEvents);
      }
    };
    fetchData();
  }, [currentView]);

  // const eventDays = ["2022-12-01", "2022-12-02", "2022-12-03"];

  const [recurrentPayments, setRecurrentPayments] = useState([
    {
      name: "pago recurrente",
      description: "Mensual",
      image: RECURRENT_EXPENSES_ICON_WB,
      amount: 0,
      recurrence: 2,
    },
  ]);
  const [recurrentIncomes, setRecurrentIncomes] = useState([
    {
      name: "ingreso recurrente",
      description: "Mensual",
      image: RECURRENT_INCOME_ICON_WB,
      amount: 0,
      recurrence: 2,
    },
  ]);
  const [reminders, setReminders] = useState([
    {
      image: REMINDER_ICON_WB,
      name: "recordatorio",
      description: "Recordatiorio de pago",
      recurrence: 2,
    },
  ]);

  const [showIncomes, setShowIncomes] = useState(true);
  const [showExpenses, setShowExpenses] = useState(true);
  const [showReminders, setShowReminders] = useState(true);
  const [showTransfers, setShowTransfers] = useState(true);
  const [showEstate, setShowEstate] = useState(true);
  const [showMyProducts, setShowMyProducts] = useState(true);

  const [editingData, setEditingData] = useState({
    id: 0,
    showInCalendar: true,
    name: "",
    description: "",
    amount: 0,
    recurrence: 0,
    date: "",
    category: 0,
    type: 0,
    url: "",
  });

  const [edit, setEdit] = useState(false);

  const [loadingEvents, setLoadingEvents] = useState(false);
  const { dayEventsData, loading, refetch, dayEvents } = getDayEvents(
    currentDay.toISOString().split("T")[0]
  );
  let data;

  if (loading) {
    console.log("Loading...");
  }
  useEffect(() => {
    const firstLoad = async () => {
      await getEventsInChangeDay(currentDay);
    };
    firstLoad();
  }, []);

  const getEventsInChangeDay = async (dateToSend: Date) => {
    setRecurrentPayments([]);
    setRecurrentIncomes([]);
    setReminders([]);

    data = await dayEvents(dateToSend.toISOString().split("T")[0]);

    data.data?.dayEvents?.forEach(
      (event: {
        type: number;
        name: any;
        description: any;
        amount: any;
        recurrence: number;
      }) => {
        if (event.type === 1) {
          setRecurrentPayments((prev) => [
            ...prev,
            {
              name: event.name,
              description: event.description,
              image: RECURRENT_EXPENSES_ICON_WB,
              amount: event.amount,
              recurrence: event.recurrence,
            },
          ]);
        } else if (event.type === 2) {
          setRecurrentIncomes((prev) => [
            ...prev,
            {
              name: event.name,
              description: event.description,
              image: RECURRENT_INCOME_ICON_WB,
              amount: event.amount,
              recurrence: event.recurrence,
            },
          ]);
        } else if (event.type === 3) {
          setReminders((prev) => [
            ...prev,
            {
              image: REMINDER_ICON_WB,
              name: event.name,
              description: event.description,
              amount: event.amount,
              recurrence: event.recurrence,
            },
          ]);
        }
      }
    );
  };

  // has the same logic than CalendarDays but shows only one week

  return (
    <>
      {currentView === "calendar" && (
        <div className={classes.container}>
          {isMobile ? (
            <>
              <div className={classes.leftColumn}>
                <div className={classes.calendarContainer}>
                  <MobileCalendar
                    setCurrentView={setCurrentView}
                    getEventsInChangeDay={getEventsInChangeDay}
                    currentDay={currentDay}
                    setCurrentDay={setCurrentDay}
                    weekly={weekly}
                    setWeekly={setWeekly}
                    eventDays={eventsData}
                    togglePage={props.togglePage}
                  />
                </div>
                <div className={classes.Calendarfilters}>
                  <CalendarFilter
                    showIncomes={showIncomes}
                    setShowIncomes={setShowIncomes}
                    showExpenses={showExpenses}
                    setShowExpenses={setShowExpenses}
                    showTransfers={showTransfers}
                    setShowTransfers={setShowTransfers}
                    showReminders={showReminders}
                    setShowReminders={setShowReminders}
                    showEstate={showEstate}
                    setShowEstate={setShowEstate}
                    showMyProducts={showMyProducts}
                    setShowMyProducts={setShowMyProducts}
                    setCurrentView={setCurrentView}
                  />
                </div>
              </div>

              <div className={classes.infoContainer}>
                <CalendarInfoContainer
                  currentDay={currentDay}
                  loading={false}
                  recurrentPayments={recurrentPayments}
                  recurrentIncomes={recurrentIncomes}
                  reminders={reminders}
                  setCurrentView={setCurrentView}
                />
              </div>
            </>
          ) : (
            <div className={classes.mobileContainer}>
              <MobileCalendar
                setCurrentView={setCurrentView}
                getEventsInChangeDay={getEventsInChangeDay}
                currentDay={currentDay}
                setCurrentDay={setCurrentDay}
                weekly={weekly}
                setWeekly={setWeekly}
                eventDays={eventsData}
                togglePage={props.togglePage}
              />

              <div id="group" className={classes.mobileGroup}>
                <div className={classes.mobileInfoContainer}>
                  <CalendarInfoContainer
                    currentDay={currentDay}
                    loading={false}
                    recurrentPayments={recurrentPayments}
                    recurrentIncomes={recurrentIncomes}
                    reminders={reminders}
                    setCurrentView={setCurrentView}
                  />
                </div>

                <div className={classes.mobileFilter}>
                  <CalendarFilter
                    showIncomes={showIncomes}
                    setShowIncomes={setShowIncomes}
                    showExpenses={showExpenses}
                    setShowExpenses={setShowExpenses}
                    showTransfers={showTransfers}
                    setShowTransfers={setShowTransfers}
                    showReminders={showReminders}
                    setShowReminders={setShowReminders}
                    showEstate={showEstate}
                    setShowEstate={setShowEstate}
                    showMyProducts={showMyProducts}
                    setShowMyProducts={setShowMyProducts}
                    setCurrentView={setCurrentView}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {currentView === "recurrentPayments" && (
        <MainEventsDetail
          type="expense"
          setCurrentView={setCurrentView}
          setEditingData={setEditingData}
          setEdit={setEdit}
          togglePage={props.togglePage}
        />
      )}
      {currentView === "recurrentIncomes" && (
        <MainEventsDetail
          type="income"
          setCurrentView={setCurrentView}
          setEditingData={setEditingData}
          setEdit={setEdit}
          togglePage={props.togglePage}
        />
      )}
      {currentView === "reminders" && (
        <MainEventsDetail
          type="reminder"
          setCurrentView={setCurrentView}
          setEditingData={setEditingData}
          setEdit={setEdit}
          togglePage={props.togglePage}
        />
      )}
      {currentView === "createPayment" && (
        <CreateEvent
          type="expense"
          title={edit ? "Editar gasto recurrente" : "Agregar gasto recurrente"}
          setCurrentView={setCurrentView}
          editing={edit}
          setEditing={setEdit}
          editingData={editingData}
        />
      )}
      {currentView === "createIncome" && (
        <CreateEvent
          type="income"
          title={
            edit ? "Editar ingreso recurrente" : "Agregar ingreso recurrente"
          }
          setCurrentView={setCurrentView}
          setEditing={setEdit}
          editing={edit}
          editingData={editingData}
        />
      )}
      {currentView === "createReminder" && (
        <CreateEvent
          type="reminder"
          title={edit ? "Editar recordatorio" : "Agregar recordatorio"}
          setCurrentView={setCurrentView}
          setEditing={setEdit}
          editing={edit}
          editingData={editingData}
        />
      )}
    </>
  );
};

export default Calendar;
