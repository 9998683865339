import { INSURANCE_M3 } from "images/GCP";
import {
  EDUCATION_WORK,
  ENTRETENIMIENT,
  FEEDING,
  FINANCE,
  GIFTS,
  HEALTH,
  LIVING_PLACE,
  OTHERS,
  RESTAURANTS,
  SHOPPING,
  SPORTS_AND_HOBBIES,
  TRANSPORTATION,
  TRIPS,
  TRANSFERS,
  HONORARIES_2D,
  CREDITS_AND_QUOTAS_2D,
  INSURANCE_2D,
} from "images/GCP/habits/addMovement";

export const categorysList = [
  {
    id: 0,
    name: "Vivienda",
    image: LIVING_PLACE,
    backgroundColor: "#00a9c2",
  },
  {
    id: 1,
    name: "Alimentación",
    image: FEEDING,
    backgroundColor: "#084455",
  },
  {
    id: 2,
    name: "Transporte",
    image: TRANSPORTATION,
    backgroundColor: "#8352fd",
  },
  {
    id: 3,
    name: "Educación y Trabajo",
    image: EDUCATION_WORK,
    backgroundColor: "#4619b6",
  },
  {
    id: 4,
    name: "Deportes y hobbies",
    image: SPORTS_AND_HOBBIES,
    backgroundColor: "#383f44",
  },
  {
    id: 5,
    name: "Viajes",
    image: TRIPS,
    backgroundColor: "#7d7d7d",
  },
  {
    id: 6,
    name: "Finanzas e impuestos",
    image: FINANCE,
    backgroundColor: "#7d117c",
  },
  {
    id: 7,
    name: "Salud y belleza",
    image: HEALTH,
    backgroundColor: "#008296",
  },
  {
    id: 8,
    name: "Compras",
    image: SHOPPING,
    backgroundColor: "#eabd29",
  },
  {
    id: 9,
    name: "Restaurantes",
    image: RESTAURANTS,
    backgroundColor: "#9803e1",
  },
  {
    id: 10,
    name: "Entretención",
    image: ENTRETENIMIENT,
    backgroundColor: "#c91ec9",
  },
  {
    id: 11,
    name: "Regalos",
    image: GIFTS,
    backgroundColor: "#40496e",
  },
  {
    id: 12,
    name: "Transferencias",
    image: TRANSFERS,
    backgroundColor: "#98ccd5",
  },
  {
    id: 13,
    name: "Otros",
    image: OTHERS,
    backgroundColor: "#181230",
  },
  {
    id: 14,
    name: "Salud",
    image: HEALTH,
    backgroundColor: "#008296",
  },
  {
    id: 15,
    name: "EDUCACIÓN",
    image: EDUCATION_WORK,
    backgroundColor: "#4619b6",
  },
  {
    id: 16,
    name: "vivienda",
    image: LIVING_PLACE,
    backgroundColor: "#00a9c2",
  },
  {
    id: 17,
    name: "cuentas",
    image: TRANSFERS,
    backgroundColor: "#98ccd5",
  },
  {
    id: 18,
    name: "entretenimiento",
    image: ENTRETENIMIENT,
    backgroundColor: "#c91ec9",
  },
  {
    id: 19,
    name: "otros",
    image: OTHERS,
    backgroundColor: "#181230",
  },
  {
    id: 20,
    name: "Pequeñas compras",
    image: SHOPPING,
    backgroundColor: "#eabd29",
  },
  {
    id: 21,
    name: "sueldo",
    image: FINANCE,
    backgroundColor: "#7d117c",
  },
  {
    id: 22,
    name: "honorarios",
    image: HONORARIES_2D,
    backgroundColor: "#979799",
  },
  {
    id: 23,
    name: "crédito y cuotas",
    image: CREDITS_AND_QUOTAS_2D,
    backgroundColor: "#282828",
  },
  {
    id: 24,
    name: "seguros",
    image: INSURANCE_2D,
    backgroundColor: "#A9A9A9",
  },
];
