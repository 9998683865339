import React, { useContext, useState, useEffect } from "react";
import { navigate, PageProps } from "gatsby";
import { makeStyles } from "@material-ui/core";
import {
  SEO,
  NameInput,
  ProfessionInput,
  JobTypeSelector,
  MaritalStateSelector,
  ButtonPrimary,
  RutInput,
  DateInput,
  GenderSelector,
  MaritalPropertyRegimeSelector,
  BirthdateInput,
  AddressInput,
} from "@components";
import { LayoutBildRegistration } from "@components/Structural/RegistrationStep1";
import { Route } from "@interfaces";
import { AppContext, MissionsContext } from "@context";

interface MoreUserInformationProps {
  form: any;
  buttonText: string;
  handleClick: () => void;
}

export const MoreUserInformation: React.FC<MoreUserInformationProps> = ({
  form,
  buttonText,
  handleClick,
}) => {
  const classes = useStyles();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [jobType, setJobType] = useState<string>(
    form.jobType ? form.jobType : ""
  );
  const [employerName, setEmployerName] = useState<string>(
    form.employerName ? form.employerName : ""
  );
  const [address, setAddress] = useState<string>(
    form.address ? form.address : ""
  );
  const [maritalStateSelector, setMaritalStateSelector] = useState(
    form.maritalState ? form.maritalState : ""
  );
  const [workPosition, setWorkPosition] = useState<string>(
    form.workPosition ? form.workPosition : ""
  );
  const [profession, setProfession] = useState<string>(
    form.profession ? form.profession : ""
  );
  const [phone, setPhone] = useState<string>(form.phone ? form.phone : "");
  //If married
  const [spouseName, setSpouseName] = useState<string>(
    form.spouseName ? form.spouseName : ""
  );
  const [spousePaternalSurname, setSpousePaternalSurname] = useState<string>(
    form.spousePaternalSurname ? form.spousePaternalSurname : ""
  );
  const [spouseMaternalSurname, setSpouseMaternalSurname] = useState<string>(
    form.spouseMaternalSurname ? form.spouseMaternalSurname : ""
  );
  const [spouseRut, setSpouseRut] = useState<string>(
    form.spouseRut ? form.spouseRut : ""
  );
  const [spouseBirthdate, setSpouseBirthdate] = useState<any>(
    form.spouseBirthdate ? form.spouseBirthdate : ""
  );
  const [spouseGender, setSpouseGender] = useState<string>(
    form.spouseGender ? form.spouseGender : ""
  );
  const [maritalPropertyRegime, setMaritalPropertyRegime] = useState<string>(
    form.maritalPropertyRegime ? form.maritalPropertyRegime : ""
  );
  const [birthdate, setBirthdate] = useState<any>(
    form.birthdate ? form.birthdate : ""
  );

  useEffect(() => {
    form.jobType = jobType;
    form.employerName = employerName;
    form.address = address;
    form.maritalState = maritalStateSelector;
    form.workPosition = workPosition;
    form.profession = profession;
    form.phone = phone;
    form.spouseName = spouseName;
    form.spousePaternalSurname = spousePaternalSurname;
    form.spouseMaternalSurname = spouseMaternalSurname;
    form.spouseRut = spouseRut;
    form.spouseBirthdate = new Date(spouseBirthdate);
    form.spouseGender = spouseGender;
    form.maritalPropertyRegime = maritalPropertyRegime;
    form.birthdate = new Date(birthdate);

    if (
      !jobType ||
      !address ||
      !maritalStateSelector ||
      !workPosition ||
      !profession ||
      !phone ||
      !birthdate
    ) {
      setButtonDisabled(true);
    } else {
      if (jobType == "Dependiente" && !employerName) {
        setButtonDisabled(true);
      } else {
        if (maritalStateSelector === "Casado/a") {
          if (
            !spouseName ||
            !spousePaternalSurname ||
            !spouseMaternalSurname ||
            !spouseRut ||
            !spouseBirthdate ||
            !spouseGender ||
            !maritalPropertyRegime
          ) {
            setButtonDisabled(true);
          } else {
            setButtonDisabled(false);
          }
        } else {
          setButtonDisabled(false);
        }
      }
    }
  }, [
    jobType,
    employerName,
    address,
    maritalStateSelector,
    workPosition,
    profession,
    phone,
    spouseName,
    spousePaternalSurname,
    spouseMaternalSurname,
    spouseRut,
    spouseBirthdate,
    spouseGender,
    maritalPropertyRegime,
    birthdate,
  ]);

  return (
    <>
      <section className={classes.container}>
        <div className={classes.form}>
          <div className={classes.inputs}>
            <div className={classes.inputs1}>
              <JobTypeSelector
                label="Tipo de trabajador"
                value={jobType}
                onChange={(e) => setJobType(e.target.value)}
              />
              <NameInput
                value={employerName}
                onChange={(e) => setEmployerName(e.target.value)}
                label="Nombre empleador (solo dependiente)"
              />
              <AddressInput
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                userUUID={form.uuid}
                required
              />
              <MaritalStateSelector
                value={maritalStateSelector}
                onChange={(e) => setMaritalStateSelector(e.target.value)}
              />
              {maritalStateSelector === "Casado/a" && (
                <>
                  <span className={classes.dataCon}>Datos cónyuge</span>
                  <NameInput
                    value={spouseName}
                    onChange={(e) => setSpouseName(e.target.value)}
                    label="Nombre Cónyuge"
                  />
                  <NameInput
                    value={spousePaternalSurname}
                    onChange={(e) => setSpousePaternalSurname(e.target.value)}
                    label="Apellido Paterno cónyuge"
                  />
                  <NameInput
                    value={spouseMaternalSurname}
                    onChange={(e) => setSpouseMaternalSurname(e.target.value)}
                    label="Apellido Materno cónyuge"
                  />
                  <RutInput
                    value={spouseRut}
                    onChange={(e) => setSpouseRut(e.target.value)}
                    label="RUT cónyuge"
                  />
                  <BirthdateInput
                    value={spouseBirthdate}
                    onChange={(e) => setSpouseBirthdate(e.target.value)}
                    label="Fecha de nacimiento cónyuge"
                  />
                  <GenderSelector
                    value={spouseGender}
                    onChange={(e) => setSpouseGender(e.target.value)}
                    label="Sexo cónyuge"
                  />
                  <MaritalPropertyRegimeSelector
                    value={maritalPropertyRegime}
                    onChange={(e) => setMaritalPropertyRegime(e.target.value)}
                    label="Régimen de bienes"
                  />
                </>
              )}
            </div>
            <div className={classes.inputs2}>
              <NameInput
                value={workPosition}
                onChange={(e) => setWorkPosition(e.target.value)}
                label="Cargo"
              />
              <ProfessionInput
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
              <NameInput
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                label="Teléfono / celular"
              />
              <BirthdateInput
                value={birthdate}
                onChange={(e) => setBirthdate(e.target.value)}
                label="Fecha de nacimiento"
              />
            </div>
          </div>
          <ButtonPrimary
            onClick={handleClick}
            className={classes.boton}
            text={buttonText}
            disabled={buttonDisabled}
            secondaryVersion
          />
        </div>
      </section>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "100%",
    position: "relative",
    minHeight: 529,
  },
  arrow: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  arrowIcon: {
    width: "4.2rem",
    height: "4.2rem",
    color: "#008296",
    marginRight: 90,
  },
  form: {},
  title: {
    fontFamily: "Montserrat",
    fontSize: "2.25rem",
    fontWeight: "bold",
    width: 452,
    color: "#393e44",
    "& span": {
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: "normal",
      display: "block",
      color: theme.palette.text.secondary,
    },
  },
  inputs: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
    [theme.breakpoints.down(1000)]: {
      marginTop: "2rem",
      gap: 23.9,
    },
  },
  inputs1: {
    display: "grid",
    width: 401.5,
    gap: 23.9,
    [theme.breakpoints.down(1000)]: {
      width: "100%",
    },
  },
  inputs2: {
    display: "grid",
    width: 401.5,
    gap: 23.9,
    marginLeft: 81.5,
    height: 264,
    [theme.breakpoints.down(1000)]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  boton: {
    width: 184,
    marginLeft: 693,
    marginTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down(1000)]: {
      width: "100%",
      height: 52,
      marginTop: 29,
      marginLeft: 0,
    },
  },
  dataCon: {
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
}));
