import React from "react";
import { Select } from "@components";
import { CommonSelectorProps, CONCERNS_OPTIONS } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}

export const InvestmentConcernSelector: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-investmentConcern-selector`}
      name={name || "investmentConcern"}
      label={label || "Cuando invierto"}
      options={CONCERNS_OPTIONS}
      {...props}
    />
  );
};
