import React, { useEffect, useState } from "react";
import { useAuth } from "@hooks";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { useStyles } from "./MyProfileMobile.style";

import MobileProfileMenu from "../MobileMenu/ProfileMobileMenu";
import { BACKWARDS_ICON } from "images/AWS/habits";
import ProfileMobile from "../ProfileMobile";
import GeneralPersonalData from "../PersonalData/GeneralPersonalData";

type MyProfileProps = {
  currentUser: any;
};
const MyProfileMobile = ({ currentUser }: MyProfileProps) => {
  console.log(currentUser);
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const setTitle = () => {
    switch (currentView) {
      case 1:
        return "Datos Personales";
      case 2:
        return "Notificaciones";
      case 3:
        return "Ajustes de Cuenta";
      case 4:
        return "Centro de Ayuda";
      default:
        return "";
    }
  };

  // 0 for main menu
  // 1 for personal data
  // 2 for notifications
  // 3 for acount settings
  // 4 for help center
  // 5 for logout
  const [currentView, setCurrentView] = React.useState(0);

  return (
    <LayoutMobileInvestments>
      {currentView === 0 ? (
        <MobileProfileMenu
          currentView={currentView}
          setCurrentView={setCurrentView}
          currentUser={currentUser}
        />
      ) : (
        <div className={classes.optionsContainer}>
          <div className={classes.header}>
            <img
              src={BACKWARDS_ICON}
              alt=""
              onClick={() => setCurrentView(0)}
            />
            <span className="title">{setTitle()}</span>
            <div />
          </div>
          <div className={classes.option}>
            {currentView === 1 && (
              <GeneralPersonalData
                currentUser={currentUser}
                setCurrentView={setCurrentView}
              />
            )}
            {currentView === 2 && <h1>vista notificaciones</h1>}
            {currentView === 3 && <h1>vista ajustes</h1>}
            {currentView === 4 && <h1>vista centro de ayuda</h1>}
          </div>
        </div>
      )}
    </LayoutMobileInvestments>
  );
};

export default MyProfileMobile;
