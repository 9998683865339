import { ApolloQueryResult, gql, useQuery } from "@apollo/client";
import { Goal } from "@interfaces";
import { useAuth } from "@hooks";

interface QueryResult {
  goals: Goal[];
}

interface HookReturn {
  goals: Goal[];
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<QueryResult>>;
}

export const GET_GOALS_QUERY = gql`
  query GetGoals {
    goals {
      id
      name
      goal
      info {
        period
        investmentFrequency
      }
      summary {
        invested
        current
        profitability
      }
      type
      monthlyInvestment
      createdAt
    }
  }
`;

export const useGoals = (): HookReturn => {
  const { isLoggedIn } = useAuth();
  const { data, loading, refetch } = useQuery<QueryResult>(GET_GOALS_QUERY, {
    skip: !isLoggedIn,
  });
  return {
    goals: isLoggedIn
      ? data?.goals.map(g => ({
          id: g.id,
          name: g.name,
          goal: g.goal,
          createdAt: new Date(g.createdAt),
          info: g.info,
          summary: g.summary,
          type: g.type,
          monthlyInvestment: g.monthlyInvestment
        })) ?? []
      : [],
    loading,
    refetch,
  };
};
