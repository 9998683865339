import React, { Dispatch, SetStateAction } from "react";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

import { useStyles } from "./calendar.styles";

import {
  Divider,
  Select,
  FormControl,
  MenuItem,
  IconButton,
  Menu,
} from "@material-ui/core";
import {
  MonthlyCalendarDays,
  WeeklyCalendarDays,
  CalendarTools,
} from "@components";

type Props = {
  currentDay: Date;
  getEventsInChangeDay: (date: Date) => void;

  setCurrentDay: (arg0: Date) => void;
  eventDays: string[] | null;
  weekly: boolean;
  setWeekly: (arg0: boolean) => void;
  setCurrentView: Dispatch<SetStateAction<string>>;
  togglePage: () => void;
};

const MobileCalendar: React.FC<Props> = ({
  currentDay,
  setCurrentDay,
  getEventsInChangeDay,
  eventDays,
  weekly,
  setWeekly,
  setCurrentView,
  togglePage,
}) => {
  // sacado de https://derrickotte.medium.com/how-to-create-a-calendar-from-scratch-in-react-1f2db197454d
  const classes = useStyles();

  const weekdays = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  // has the same logic than CalendarDays but shows only one week

  const changeCurrentDay = (day: {
    year: number;
    month: number;
    number: number | undefined;
  }) => {
    const dateToSet = new Date(day.year, day.month, day.number);
    setCurrentDay(dateToSet);
    getEventsInChangeDay(dateToSet);
  };

  const nextDay = () => {
    // add one month to current day
    if (weekly) {
      const dateToSet = new Date(
        currentDay.getFullYear(),
        currentDay.getMonth(),
        currentDay.getDate() + 7
      );
      setCurrentDay(dateToSet);
      getEventsInChangeDay(dateToSet);
    } else {
      const dateToSet = new Date(
        currentDay.getFullYear(),
        currentDay.getMonth() + 1
      );

      setCurrentDay(dateToSet);
      getEventsInChangeDay(dateToSet);
    }
  };

  const previousDay = () => {
    // subtract one month to current day
    if (weekly) {
      const dateToSet = new Date(
        currentDay.getFullYear(),
        currentDay.getMonth(),
        currentDay.getDate() - 7
      );
      setCurrentDay(dateToSet);
      getEventsInChangeDay(dateToSet);
    } else {
      const dateToset = new Date(
        currentDay.getFullYear(),
        currentDay.getMonth() - 1
      );
      setCurrentDay(dateToset);
      getEventsInChangeDay(dateToset);
    }
  };

  return (
    <div
      className={` ${classes.calendar} ${
        weekly ? ` ${classes.weelySize}` : ""
      }`}
    >
      <CalendarTools
        setWeekly={setWeekly}
        weekly={weekly}
        setCurrentView={setCurrentView}
        setCurrentDay={setCurrentDay}
        getEventsInChangeDay={getEventsInChangeDay}
      />
      <div className="tools">
        <IconButton onClick={previousDay}>
          <ArrowBackRoundedIcon />
        </IconButton>
        <p className="mainMonth">
          {months[currentDay.getMonth()].substring(0, 3)}{" "}
          {currentDay.getFullYear()}
        </p>
        <IconButton onClick={nextDay}>
          <ArrowForwardRoundedIcon />
        </IconButton>
      </div>
      <div className="calendar-body">
        <div className="table-header">
          {weekdays.map((weekday) => {
            return (
              <div className="weekday" key={weekday}>
                <p>{weekday}</p>
              </div>
            );
          })}
        </div>
        <Divider variant="middle" className={classes.dividerColor} />
        {!weekly ? (
          <MonthlyCalendarDays
            day={currentDay}
            changeCurrentDay={changeCurrentDay}
            eventDays={eventDays}
            togglePage={togglePage}
          />
        ) : (
          <WeeklyCalendarDays
            day={currentDay}
            changeCurrentDay={changeCurrentDay}
            eventDays={eventDays}
            togglePage={togglePage}
          />
        )}
      </div>
    </div>
  );
};

export default MobileCalendar;
