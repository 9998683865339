import { ApolloClient, gql } from "@apollo/client";
import { DBInvestment, Movement } from "@interfaces";
import { GET_USER_INVESTMENTS_QUERY } from "..";

export interface CreateMovementPayload {
  type: Movement["type"];
  serieId: number;
  amount: Movement["amount"];
  date: Movement["date"];
  investmentType: DBInvestment["type"];
}

const CREATE_MOVEMENTS_MUTATION = gql`
  mutation CreateUserInvestmentMovements(
    $payloads: [UserInvestmentMovementCreatPayload!]!
  ) {
    createUserInvestmentMovements(payloads: $payloads) {
      id
      date
      amount
      type
      status
    }
  }
`;

type CreateMovementsMutationParams = {
  payloads: CreateMovementPayload[];
};

type CreateMovementsMutationResult = {
  createUserInvestmentMovements: Movement | null;
};

export async function createUserInvestmentMovements(
  movementsPayloads: CreateMovementPayload[],
  refetchInvestments = true,
  client: ApolloClient<object>,
): Promise<boolean> {
  await client.mutate<
    CreateMovementsMutationResult,
    CreateMovementsMutationParams
  >({
    mutation: CREATE_MOVEMENTS_MUTATION,
    variables: { payloads: movementsPayloads },
    refetchQueries: refetchInvestments
      ? [
          {
            query: GET_USER_INVESTMENTS_QUERY,
          },
        ]
      : undefined,
  });
  return true;
}
