import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button as MuiButton, Theme } from '@material-ui/core';
import { COMPONENT_SIZES, SizeType } from '@styles';

type Props = {
  className?: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  size?: SizeType; // 30, 36, 44, 48, 56
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  secondaryVersion?: boolean;
};

export const ButtonOutlined: React.FC<Props> = ({
  className,
  text,
  onClick,
  disabled,
  fullWidth,
  startIcon,
  size = 'large',
  onMouseEnter,
  onMouseLeave,
  secondaryVersion,
}) => {
  const classes = useStyles({ size, secondaryVersion });
  return (
    <MuiButton
      className={`${className} ${classes.root}`}
      variant='outlined'
      onClick={onClick}
      color='primary'
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={startIcon}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disableRipple
    >
      {text}
    </MuiButton>
  );
};

const useStyles = makeStyles<
  Theme,
  { size: Props['size']; secondaryVersion?: boolean; disabled?: boolean }
>((theme) => ({
  root: {
    border: ({ secondaryVersion }) =>
      secondaryVersion
        ? 'solid 2px #4619b6'
        : `solid 2px ${theme.palette.primary.main}`,
    color: '#393e44',
    fontWeight: 400,
    boxShadow: '1px 2px 5px 1px rgb(0 0 0 / 25%)',
    textTransform: 'none',
    height: ({ size }) => COMPONENT_SIZES[size ?? 'large'],
    borderRadius: 10,
    fontweight: 500,
    '&:hover': {
      backgroundColor: ({ secondaryVersion }) =>
        secondaryVersion ? '#4619b6' : `${theme.palette.primary.main}`,
      border: ({ secondaryVersion }) =>
        secondaryVersion
          ? 'solid 1px #4619b6'
          : `solid 1px ${theme.palette.primary.main}`,
      color: 'white',
    },
  },
}));
