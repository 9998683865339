import { AuthContext, GoalsContext, LoadingContext } from "@context";
import {
  ChangeEvent,
  DEFAULT_REGISTER_FORM,
  OnChangeType,
  RegisterForm,
  Route,
  ProfileForm,
  UseType,
} from "@interfaces";
import { navigate } from "gatsby";
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { calculateSuggestedProfile } from "@utils";
import { sendEvent } from "utils/facebook-pixel";

interface RegisterContextType {
  form: RegisterForm;
  setForm: React.Dispatch<React.SetStateAction<RegisterForm>>;
  handleChange: OnChangeType;
  finishRegister: (mailing: boolean) => void;
  finishRegisterWithGoogle: (mailing: boolean) => void;
  finishRegisterAfterGoogle: (mailing: boolean) => Promise<void>;
}

export const RegisterContext = createContext({} as RegisterContextType);

export const RegisterProvider: React.FC = ({ children }) => {
  const {
    registerWithEmailPassword,
    registerWithGoogle,
    createUserAfterGoogle,
  } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [form, setForm] = useState<RegisterForm>(DEFAULT_REGISTER_FORM);
  const { addGoal } = useContext(GoalsContext);

  const handleChange = (event: ChangeEvent) => {
    const name = event.target.name as keyof RegisterForm;
    const value = event.target.value;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    // Try to calculate suggested investor profile
    const result = calculateSuggestedProfile(
      form.investmentTerm as ProfileForm["investmentTerm"],
      {
        risk: form.investmentRisk,
        investmentExperience: form.investmentExperience,
        investmentConcern: form.investmentConcern,
        currentInvesting: form.currentInvesting,
        investmentDropResponse: form.investmentDropResponse,
      }
    );
    if (result.completed) {
      setForm((prev) => ({ ...prev, investorProfile: result.profile }));
    }
  }, [
    form.investmentTerm,
    form.investmentRisk,
    form.investmentExperience,
    form.investmentConcern,
    form.currentInvesting,
    form.investmentDropResponse,
  ]);

  const userAfterCreate = useCallback(
    (form: RegisterForm) => {
      const shouldCreateGoal = form.useType === UseType.GOAL;
      if (shouldCreateGoal) {
        addGoal({
          name: form.goalName,
          goal: form.goalAmount,
          period: form.goalTerm,
          investmentFrequency: null,
        });
      }
    },
    [addGoal]
  );

  const resetAndNavigateOut = useCallback(
    (mailing) => {
      navigate(Route.producTour);
      setForm(DEFAULT_REGISTER_FORM);
    },
    [setForm]
  );

  const onSuccessfullRegistering = useCallback(
    (mailing) => {
      sendEvent({ event: "CompleteRegistration" });
      userAfterCreate(form);
      resetAndNavigateOut(mailing);
    },
    [form, userAfterCreate, resetAndNavigateOut]
  );

  /* Función para terminar el flujo de registro luego de
    loguearse con google por 1ra vez  */
  const finishRegisterAfterGoogle = useCallback(
    async (mailing) => {
      try {
        showLoader("creating new user");
        // Create user at database
        const success = await createUserAfterGoogle({
          name: form.name,
          email: form.email,
          investorProfile: form.investorProfile as string,
          investmentTerm: form.investmentTerm,
          risk: form.investmentRisk,
          investmentExperience: form.investmentExperience,
          investmentConcern: form.investmentConcern,
          currentInvesting: form.currentInvesting,
          investmentDropResponse: form.investmentDropResponse,
          manuallySelectedInvestorProfile: !form.investmentTerm,
          onSuccess: () => {
            hideLoader("creating new user");
            onSuccessfullRegistering(mailing);
          },
          onFailure: () => {
            hideLoader("creating new user");
          },
        });
        if (!success) throw new Error("user_not_created");
      } catch (error) {
        console.error(error);
      }
    },
    [
      form,
      showLoader,
      hideLoader,
      createUserAfterGoogle,
      onSuccessfullRegistering,
    ]
  );

  const finishRegister = useCallback(
    (mailing) => {
      showLoader("creating new user");
      registerWithEmailPassword({
        email: form.email,
        password: form.password,
        name: !form.name ? form.email : form.name,
        investorProfile: form.investorProfile as string,
        investmentTerm: form.investmentTerm,
        risk: form.investmentRisk,
        investmentExperience: form.investmentExperience,
        investmentConcern: form.investmentConcern,
        currentInvesting: form.currentInvesting,
        investmentDropResponse: form.investmentDropResponse,
        manuallySelectedInvestorProfile: !form.investmentTerm,
        onSuccess: () => {
          hideLoader("creating new user");
          onSuccessfullRegistering(mailing);
        },
        onFailure: () => {
          hideLoader("creating new user");
        },
      });
    },
    [form, showLoader, hideLoader, onSuccessfullRegistering]
  );

  const finishRegisterWithGoogle = useCallback(
    async (mailing) => {
      showLoader("creating new user");

      registerWithGoogle({
        name: form.name,
        investorProfile: form.investorProfile as string,
        investmentTerm: form.investmentTerm,
        risk: form.investmentRisk,
        investmentExperience: form.investmentExperience,
        investmentConcern: form.investmentConcern,
        currentInvesting: form.currentInvesting,
        investmentDropResponse: form.investmentDropResponse,
        manuallySelectedInvestorProfile: !form.investmentTerm,
        onSuccess: () => {
          hideLoader("creating new user");
          onSuccessfullRegistering(mailing);
        },
        onFailure: () => {
          hideLoader("creating new user");
        },
      });
    },
    [form, showLoader, hideLoader, onSuccessfullRegistering]
  );

  const context: RegisterContextType = useMemo(
    () => ({
      form,
      setForm,
      handleChange,
      finishRegister,
      finishRegisterWithGoogle,
      finishRegisterAfterGoogle,
    }),
    [
      form,
      setForm,
      handleChange,
      finishRegister,
      finishRegisterWithGoogle,
      finishRegisterAfterGoogle,
    ]
  );
  return (
    <RegisterContext.Provider value={context}>
      {children}
    </RegisterContext.Provider>
  );
};
