import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { LayoutDesktop } from "./LayoutDesktop";
import { LayoutMobile } from "./LayoutMobile";
import { EditGoal } from "./EditGoal";

interface LayoutProps {
  className?: string;
  styleFooter?: string;
  icon: string;
  buttonContinue: string;
  title: string;
  secondTitle?: string;
  goal?: number;
  goalId?: number;
  goalPeriod?: number;
  goalType?: string;
}
export const LayoutGoal = ({
  className,
  icon,
  buttonContinue,
  title,
  secondTitle,
  goal,
  goalId,
  goalPeriod,
  goalType,
}: LayoutProps) => {
  const isMobile = useMediaQuery("(max-width: 1280px)");

  return !isMobile ? (
    <LayoutDesktop className={className} icon={icon}>
      <EditGoal
        className={className}
        icon={icon}
        buttonContinue={buttonContinue}
        title={title}
        secondTitle={secondTitle}
        goal={goal}
        goalId={goalId}
        goalPeriod={goalPeriod}
        goalType={goalType}
      />
    </LayoutDesktop>
  ) : (
    <LayoutMobile
      className={className}
      icon={icon}
      iconFooter={icon}
      stepNumber={2}
      pageName={"Editar Objetivo"}
    >
      <EditGoal
        className={className}
        icon={icon}
        buttonContinue={buttonContinue}
        title={title}
        secondTitle={secondTitle}
        goal={goal}
        goalId={goalId}
        goalPeriod={goalPeriod}
        goalType={goalType}
      />
    </LayoutMobile>
  );
};
