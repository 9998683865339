import { ApolloClient, gql } from "@apollo/client";
import { AltercapUser as User, ProviderType, RegisterForm } from "@interfaces";

const CREATE_USER = gql`
  mutation CreateUser($data: CreateUserParams!) {
    createUser(data: $data) {
      id
      name
      email
    }
  }
`;

//TODO: Better way to tpye this
type CreateUserParams = Partial<{
  name: string;
  email: string;
  password: string;
  provider: ProviderType;
  investorProfile: string;
  investmentTerm: RegisterForm["investmentTerm"];
  risk: RegisterForm["investmentRisk"];
  investmentExperience: RegisterForm["investmentExperience"];
  investmentConcern: RegisterForm["investmentConcern"];
  currentInvesting: RegisterForm["currentInvesting"];
  investmentDropResponse: RegisterForm["investmentDropResponse"];
  manuallySelectedInvestorProfile: boolean;
}>;

type MutationParams = {
  data: Omit<CreateUserParams, "birthdate">;
};

type CreateUserResult = {
  createUser: User | null;
};

export async function createUser(
  data: Partial<CreateUserParams>,
  client: ApolloClient<object>,
): Promise<User | null> {
  console.log(data, "----data");
  const result = await client.mutate<CreateUserResult, MutationParams>({
    mutation: CREATE_USER,
    variables: {
      data,
    },
  });
  console.log("Termina de pasar por la mutación");
  console.log(result, "---result");

  return result.data?.createUser ?? null;
}
