import React, { useEffect, useState } from 'react';
import { useStyles } from './IncomeCard.style';
import { ProgressBarLine } from 'react-progressbar-line';
import {
  IncomeIcon,
  BonoIcon,
  AguinaldoIcon,
  AlimentacionIcon,
  ArriendoIcon,
  ComisionIcon,
  CreditIcon,
  CuentasIcon,
  EntretenimientoIcon,
  GastoDefaultIcon,
  HonorariosIcon,
  IncomeDefault,
  OthersIcon,
  SmallPurchases,
  SaludIcon,
  SegurosIcon,
  TransporteIcon,
  ViviendaIcon,
} from '@images';
import { getCategoryIcon } from '@components/FinancialPortal/Habits/MyExpenses/MyExpenses';
import { categorysList } from '@data';
import { parseNumber } from '@utils';
import { categorysList3D } from 'data/categoryList3D';
import { FullLoader } from '@components/Reusables';

interface Card {
  name: string;
  total: number;
  amount: number;
  percentage: number;
  type: string;
  goal: number;
}

const IncomeCard = ({ name, total, amount, percentage, type, goal }: Card) => {
  useEffect(() => {
    setGoalState(goal);
  }, [goal]);

  useEffect(() => {
    setAmountState(amount);
  }, [amount]);

  const classes = useStyles();

  const category = categorysList.find((item) => item.name === name);
  const categoryList3D = categorysList3D.find((item) => item.name === name);

  const left = Math.max(0, goal - amount);

  const [goalState, setGoalState] = useState(100);
  const [amountState, setAmountState] = useState(5);
  return (
    <>
      <div className={classes.container}>
        <img className={classes.iconBox} src={category?.image} />
        <div className={classes.progress}>
          <div className={classes.titlePercentage}>
            {name}
            <span>${parseNumber(amount)}</span>
          </div>
          <ProgressBarLine
            value={amountState}
            min={1}
            max={goalState}
            text=" "
            styles={{
              path: {
                stroke: category?.backgroundColor,
              },
            }}
          />
          <div className={classes.goals}>
            <div className={classes.goal}>Meta: $ {parseNumber(goal)}</div>
            <div className={classes.left}>Quedan: $ {parseNumber(left)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeCard;
