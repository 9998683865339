import { gql, useQuery } from "@apollo/client";
import { FinerioTransaction, FinerioTransactionReturn } from "@interfaces";
import _ from "lodash";

export const GET_FINERIO_TRANSACTIONS = gql`
  query FinerioTransactions($accountId: Int) {
    finerioTransactions(accountId: $accountId) {
      id
      accountId
      amount
      balance
      date
      description
      isCharge
      newSubcategory {
        FinerioCategory {
          icon
          id
          name
        }
        description
        icon
        subcategoryId
        id
      }
      subcategoryId {
        FinerioCategory {
          icon
          id
          name
        }
        description
        id
        icon
        subcategoryId
      }
      recommendedSubcategory
    }
  }
`;

type QueryResult = {
  finerioTransactions: FinerioTransaction[];
};

type HookReturn = {
  finerioTransactions: FinerioTransaction[];
  finerioTransactionsLoading: boolean;
  refetchFinerioTransactions: () => void;
};

export const getFinerioTransactions = (accountId?: string): HookReturn => {
  const { data, loading, refetch } = useQuery<QueryResult>(
    GET_FINERIO_TRANSACTIONS,
    {
      variables: { accountId },
    }
  );

  const cloned = _.cloneDeep(data?.finerioTransactions);

  cloned?.forEach((transaction) => {
    let formattedDate = new Intl.DateTimeFormat("en-US").format(
      new Date(Number(transaction.date))
    );
    // MM/DD/YYYY
    transaction.date = new Date(formattedDate);
  });

  cloned?.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  return {
    finerioTransactions: cloned ?? [],
    finerioTransactionsLoading: loading,
    refetchFinerioTransactions: refetch,
  };
};
