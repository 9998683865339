import TermFilterDefault from "./term-filter-defaults.json";
import AllocationPercentages from "./allocations-percentages.json";
import QuestionsScores from "./questions-scores.json";
import ProfileScoreRanges from "./profile-score-ranges.json";
import InvestTypeCategoryMapping from "./invest-type-category-mapping.json";
import {
  InvestmentTermType,
  InvestorProfileType,
  InvestType,
  ProfileForm,
} from "@interfaces";

export const getDefaultTermFilter = (
  investorProfile: InvestorProfileType | null,
  investType?: InvestType | null,
): InvestmentTermType[] => {
  if (!investorProfile || !investType) return [];
  return TermFilterDefault[investorProfile][investType] as InvestmentTermType[];
};

export const getAllocationPercentages = (
  investorProfile: InvestorProfileType,
): Record<InvestType, number> => {
  return AllocationPercentages[investorProfile];
};

type Field =
  | "risk"
  | "investmentExperience"
  | "investmentConcern"
  | "currentInvesting"
  | "investmentDropResponse";
export const INVESTOR_PROFILE_FIELDS: Field[] = [
  "risk",
  "investmentExperience",
  "investmentConcern",
  "currentInvesting",
  "investmentDropResponse",
];
export const calculateSuggestedProfile = (
  selectedTerm: ProfileForm["investmentTerm"],
  formAnswers: Pick<ProfileForm, Field>,
): { completed: boolean; profile: InvestorProfileType | null } => {
  if (!selectedTerm) return { completed: false, profile: null };
  const completed = INVESTOR_PROFILE_FIELDS.every(field =>
    Boolean(formAnswers[field]),
  );
  const score = INVESTOR_PROFILE_FIELDS.reduce(
    (acum, field) =>
      acum +
      ((QuestionsScores as Record<Field, Record<string, number>>)[field][
        formAnswers[field]
      ] ?? 0),
    0,
  );

  const entry = Object.entries(ProfileScoreRanges[selectedTerm]).find(
    ([range]) => {
      const [min, max] = range.split("-").map(Number);
      return min <= score && score <= max;
    },
  ) as [string, InvestorProfileType];
  return { completed, profile: entry?.[1] ?? null };
};

export const getCategoryFromInvestType = (
  investType?: InvestType | null,
): string[] => {
  if (!investType) return [];
  return InvestTypeCategoryMapping[investType];
};
